import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'

export interface IFavoriteEntity {
  id: string
  isActive: boolean
  name: string
}
export interface TListFavoritesResponse {
  hasNextPage: boolean
  total: number
  data: IFavoriteEntity[]

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
}[]

export class FavoritesApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public listFavorites = async (): Promise<Result<TListFavoritesResponse>> => {
    try {
      const result = await this.service.get('/favorites', { params: { page: 1, offset: 100 } })

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public createFavorite = async (name: string): Promise<Result<string | undefined>> => {
    try {
      const { data } = await this.service.post('/favorite', { name })

      return Result.ok(data.id)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public deleteFavorite = async (id: string): Promise<Result<void>> => {
    try {
      await this.service.delete(`/favorite/${id}`)

      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
