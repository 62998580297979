/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Box, Button, Flex, Image, Text, useTheme } from '@chakra-ui/react'
import React, { FC, useEffect, useRef, useState } from 'react'
import { MdLogin } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import IAButton from '../../Assets/ia-button.png'
import { ArrowLeftIcon } from '../../Assets/IconsComponents/ArrowLeft'
import { useChat } from '../../Hooks/useChat'
import { useScreenSizes } from '../../utils/screenSizes'
import { ModalSignOut } from '../Modals/ModalSignOut'
import InfoFilesPopover from '../Popover/InfoFilesPopover/info-files-popover.component'
import { BackButtonHeader } from './BackButton'

interface IHeaderProps {
  hideSidebar?: boolean
  style?: object

}
const Header: FC<IHeaderProps> = ({ hideSidebar = false, ...rest }) => {
  const [openModal, setOpenModal] = useState(false)
  const { isMobile, isMidScreen, isLargeScreen } = useScreenSizes()
  const { isChatOpen, setIsChatOpen, setVersionAI, handleCreateThread, currentCustomerName, userAllowedToChangeCustomer, aiEnabled, filesData, loadingFiles } = useChat()
  const [rightWidthComponent, seTrightWidthComponent] = useState(0)
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()

  const RightHeaderRef = useRef<HTMLDivElement>(null)

  const handleBack = () => {
    navigate('/', { state: { fromFullScreen: true } })
  }

  const isBackofficeRoute = window.location.pathname.includes('/backoffice')

  const toggleChat = (): void => {
    // if (!isChatOpen) {
    //   if (typeof window !== 'undefined' && window.gtag) {
    //     window.gtag('event', 'click', {
    //       event_category: 'Interaction',
    //       event_label: 'Open Chat Button'
    //     })
    //   }
    // }
    if (isMobile) {
      navigate('/ai?fromFloatButton=true')
      return
    }
    setIsChatOpen(!isChatOpen)
  }

  useEffect(() => {
    if (RightHeaderRef?.current) {
      seTrightWidthComponent(RightHeaderRef?.current?.offsetWidth)
    }
  }, [])

  return (

    <>
      {openModal && <ModalSignOut onClose={() => setOpenModal(false)} /> }

      { isMobile ? (
          <Flex
            as="header"
            bg="white"
            w="100%"
            position='fixed'
            z-index={999}
            h={!isMobile ? '111px' : '60px'}
            direction={!isMobile ? 'column' : 'row'}
            borderBottom="1px"
            borderColor="#E9E9E9"
            paddingX="20px"
            paddingY={!isMobile ? '10px' : 0}
            top={0}
            {...rest}
          >
            <Flex
              alignItems="center"
              marginTop="10px"
              marginBottom="10px"
              justifyContent="space-between"
              w="100%"
            >
               <Box onClick={handleBack} cursor='pointer'>
                <BackButtonHeader />
             </Box>

              {/* <Flex direction="column" >
                <Logo />
              </Flex> */}

              <Flex position='absolute' right='24px' top='17px'>
                {/* {canChangeAiVersion && <RadioGroup onChange={async (value) => await handleChangeAI(Number(value))} value={versionAI} name="version">
                    <Flex gap="20px" marginRight="20px">
                      <Radio value={1}>Versão 1.0</Radio>
                      <Radio value={2}>Versão 2.0</Radio>
                    </Flex>
                  </RadioGroup>
                } */}
                <Box marginRight='10px'>
                  <InfoFilesPopover data={filesData} loading={loadingFiles} position="bottom" />
                </Box>

                <Box right='24px' top='16px' onClick={() => setOpenModal(true)} cursor='pointer'>
                  <MdLogin size='24px' />
                </Box>
              </Flex>

            </Flex>
          </Flex>
        ) : (

          <>
            <Flex padding={!hideSidebar ? 0 : '10px'} {...rest}>

              {!isBackofficeRoute && !['/ai', '/terms-of-use', '/terms-of-privacy'].includes(location.pathname) && aiEnabled ? (

                  <Flex alignItems='center' marginTop='5px'>
                    <Image
                      src={IAButton}
                      w='50px'
                      h='50px'

                      zIndex={999}
                    />
                    <Flex alignItems='center' marginLeft='10px'>
                      <Text fontWeight={400} fontSize='18px' fontFamily={theme.fonts.bodyBold}>Já consultou sua IA hoje?</Text>  <Text marginLeft='10px'>Converse com seus dados</Text> <Text textDecoration='underline' color='#0000FF' marginLeft='5px' onClick={toggleChat} cursor='pointer'>agora</Text>
                    </Flex>

                  </Flex>

              ) : <Box height='60px'/>}

              <Flex position='absolute' right='24px' top='28px' {...rest}>

                {!isBackofficeRoute && userAllowedToChangeCustomer && <Flex mr='20px'>
                  <Button onClick={() => navigate('/backoffice/select-customer?fromChat=true')} marginRight='10px' height='25px' bgColor='#616480'><Text color='#FFFFFF'>Trocar</Text></Button>
                    <Text fontWeight={500}>Logado como cliente: <b>{currentCustomerName?.toUpperCase()}</b></Text>

                  </Flex>
                }

                <Box right='24px' top='16px' onClick={() => setOpenModal(true)} cursor='pointer'>
                  <MdLogin size='24px' />
                </Box>
              </Flex>
              </Flex>

              {!hideSidebar && (
                 <Flex
                 backgroundColor="#EFF4FA"
                 width="109px"
                 height="100vh"
                 paddingTop="36px"
                 direction="column"
                 alignItems="center"
                 zIndex={99}
               >
                   <Box onClick={handleBack} cursor='pointer'>
                   <ArrowLeftIcon />
                   </Box>

               </Flex>
              )}

          </>
        ) }
    </>
  )
}

export { Header }
