import {
  FormControl,
  FormLabel,
  Select as SelectChakra,
  SelectProps,
  FormErrorMessage,
  theme,
  useTheme
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import React, { forwardRef, ForwardRefRenderFunction } from 'react'

export interface OptionsProps {
  value: string | number
  label: string
}
interface Props extends SelectProps {
  name: string
  label?: string
  errors?: any
  options: OptionsProps[]
  labelStyle?: any
}

const BaseSelect: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  { name, label, errors = null, options, labelStyle, ...rest },
  ref
) => {
  const theme = useTheme()

  return (
  <FormControl id={name} isInvalid={!(errors == null)}>
    {!!label && <FormLabel color="black.primary" style={labelStyle} fontFamily={theme.fonts.bodyBold}>{label}</FormLabel>}
    <SelectChakra
      name={name}
      id={name}
      bg="white"
      variant="filled"
      border="1px"
      borderColor="blue.500"
      ref={ref}
      minHeight="48px"
      {...rest}
    >
      {options.map((i: OptionsProps) => (
        <option key={i.value} value={i.value}>
          {i.label}
        </option>
      ))}
    </SelectChakra>
    {!(errors == null) && <FormErrorMessage>{errors.message}</FormErrorMessage>}
  </FormControl>
  )
}

export const Select = forwardRef(BaseSelect)
