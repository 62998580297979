import { ICompanyListPayload, ICompanyListResponse } from '../../Services/interfaces/companies.interface'
import { CompaniesApiRemoteService } from '../../Services/protocols/service-companies'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ListCompanyUseCase implements IUsecase<ICompanyListPayload, ICompanyListResponse> {
  constructor (private readonly repository: CompaniesApiRemoteService) {}

  public async handle (payload: ICompanyListPayload): Promise<Result<ICompanyListResponse>> {
    try {
      console.log('payload: ', payload)
      const response = await this.repository.list(payload)

      const data = response.getValue()

      if (data === null) {
        return Result.fail(new Error('Clientes não encontrados.'))
      }

      return Result.ok(data)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
