import { IUserEntity, TDeleteUserPayload, UserApiRemoteService } from '../../Services/protocols/service-user'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ActivateUserUseCase implements IUsecase<TDeleteUserPayload, IUserEntity> {
  constructor (private readonly repository: UserApiRemoteService) {}

  public async handle (payload: TDeleteUserPayload): Promise<Result<IUserEntity>> {
    try {
      await this.repository.activate(payload)

      return Result.ok()
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
