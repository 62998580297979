import { useBreakpointValue } from '@chakra-ui/react'

const useScreenSizes = (): { isMobile: boolean | undefined; isMidScreen: boolean | undefined; isLargeScreen: boolean | undefined } => {
  const isMobile = useBreakpointValue({
    base: true, // Considera qualquer dispositivo como mobile
    sm: true, // Small devices and up
    md: false, // Medium devices and up
    lg: false, // Large devices and up
    xl: false // Extra large devices and up
  })

  const isMidScreen = useBreakpointValue({
    base: false, // Considera qualquer dispositivo como mobile
    sm: false, // Small devices and up
    md: true, // Medium devices and up
    lg: false, // Large devices and up
    xl: false // Extra large devices and up
  })

  const isLargeScreen = useBreakpointValue({
    base: false, // Considera qualquer dispositivo como mobile
    sm: false, // Small devices and up
    md: false, // Medium devices and up
    lg: true, // Large devices and up
    xl: true // Extra large devices and up
  })

  return {
    isMobile,
    isMidScreen,
    isLargeScreen
  }
}

export { useScreenSizes }
