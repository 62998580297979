import React from 'react'
import {
  Tag,
  TagLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody
} from '@chakra-ui/react'
import { TIntegrationSyncStage } from '../../Services/interfaces/integration.interface'

interface StatusTagProps {
  status: TIntegrationSyncStage
  errorMessage?: string // Mensagem de erro opcional
}

const statusColors = {
  START: 'blue',
  SYNCING: 'yellow',
  SYNCED: 'green',
  ERROR: 'red'
}

const labelTag = {
  START: 'Aguardando Sincronização',
  SYNCING: 'Sincronizando',
  SYNCED: 'Sincronizado',
  ERROR: 'Erro'
}

export const SyncTag: React.FC<StatusTagProps> = ({ status, errorMessage }) => {
  if (status === 'ERROR' && errorMessage) {
    return (
      <Popover>
        <PopoverTrigger>
          <Tag colorScheme="red" borderRadius="full" px={10} height="44px" cursor="pointer" minWidth="auto">
            <TagLabel fontSize="17px" fontWeight="500" whiteSpace="nowrap">{labelTag[status]}</TagLabel>
          </Tag>
        </PopoverTrigger>
        <PopoverContent bg="red.500" color="white">
          <PopoverArrow bg="red.500" />
          <PopoverCloseButton color="white" />
          <PopoverBody fontWeight="500">{errorMessage}</PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <Tag colorScheme={statusColors[status]} borderRadius="full" px={10} height="44px" minWidth="auto">
      <TagLabel fontSize="17px" fontWeight="500" whiteSpace="nowrap">{labelTag[status]}</TagLabel>
    </Tag>
  )
}
