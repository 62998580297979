import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUserEntity } from './service-user'
import { TUpdateCompanyPayload, ICompanyEntity, TCreateCompanyPayload, IRolesAndAreasResponse, ICompanyListResponse, ICompanyListPayload } from '../interfaces/companies.interface'

export class CompaniesApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public update = async ({ clientName, collectionName, compaynId, document, marketingEnabled, salesEnabled }: TUpdateCompanyPayload): Promise<Result<void>> => {
    try {
      await this.service.put(`/company/${compaynId}`, { clientName, document, salesEnabled, marketingEnabled, collectionName })

      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public show = async (id: string): Promise<Result<ICompanyEntity>> => {
    try {
      const resp = await this.service.get(`/companies/${id}`)

      return Result.ok(resp.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public list = async ({ page, search }: ICompanyListPayload): Promise<Result<ICompanyListResponse>> => {
    try {
      const resp = await this.service.get('/companies', {
        params: {
          page,
          searchBy: search,
          offset: 10
        }
      })

      return Result.ok(resp.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public create = async (data: TCreateCompanyPayload): Promise<Result<void>> => {
    try {
      await this.service.post('/setup/company', data)

      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public listRolesAndAreas = async (): Promise<Result<IRolesAndAreasResponse>> => {
    try {
      const result = await this.service.get('/companies/roles-and-areas')

      return Result.ok(result.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
