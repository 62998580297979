import { ICompanyEntity } from '../../Services/interfaces/companies.interface'
import { CompaniesApiRemoteService } from '../../Services/protocols/service-companies'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ShowCompanyUseCase implements IUsecase<string, ICompanyEntity> {
  constructor (private readonly repository: CompaniesApiRemoteService) {}

  public async handle (id: string): Promise<Result<ICompanyEntity>> {
    try {
      const response = await this.repository.show(id)

      const data = response.getValue()

      if (data === null) {
        return Result.fail(new Error('Empresa não encontrados.'))
      }

      return Result.ok(data)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
