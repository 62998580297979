import { Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { useChat } from '../../Hooks/useChat'
import { DecodeJwt } from '../../utils/decode-jwt'
import { useNavigate, useSearchParams } from 'react-router-dom'

interface Props {
  onClose: () => void
}

export const goToNewCustomer = 'administrativo@nalk.com.br'
// export const goToNewCustomer = 'diego@nalk.com.br'
export const goToIntegrationsList = ''
// export const goToNewCustomer = 'diego@nalk.com.br'
export const goToSelectCustomer = ['lucca@nalk.com.br', 'jose@nalk.com.br', 'jaquelyne@nalk.com.br', 'karol@nalk.com.br', 'diego@nalk.com.br', 'douglas@nalk.com.br', 'arinca@nalk.com.br', 'nicholas@nalk.com.br', 'sabrina@nalk.com.br', 'diegomalta@nalk.com.br', 'lucas@nalk.com.br']
export const allowedToChangeCustomer = ['lucca@nalk.com.br', 'jose@nalk.com.br', 'jaquelyne@nalk.com.br', 'karol@nalk.com.br', 'diego@nalk.com.br', 'paulo@nalk.com.br', 'arinca@nalk.com.br', 'lucas@nalk.com.br', 'diegomalta@nalk.com.br']

const ModalChoiceAccess: FC<Props> = ({ onClose }) => {
  const { userToken, userAllowedToChangeCustomer } = useChat()
  const navigate = useNavigate()

  const validateAccess = (): void => {
    const decoded = DecodeJwt(userToken)

    if (decoded?.userEmail === goToNewCustomer) {
      navigate('/backoffice/companies')
      onClose()
      return
    }

    if (decoded?.userEmail === goToIntegrationsList) {
      navigate('/backoffice/integrations')
      onClose()
      return
    }

    if (goToSelectCustomer.includes(decoded?.userEmail)) {
      navigate('/backoffice/select-customer')
      onClose()
    }
  }

  // const sendToChoiceCustomer = () => {
  //   onClose()
  //   navigate('/backoffice/select-customer?fromChat=true')
  // }

  return (
        <Modal isOpen onClose={() => {}} size="4xl" >
            <ModalOverlay backgroundColor='rgba(0,0,0,0.8)' />
            <ModalContent>
            <ModalHeader textAlign="center" mt='40px'>Qual o tipo de acesso que você deseja?</ModalHeader>

            <ModalBody>
              <Flex gap='20px' justifyContent='center' padding='40px 20px'>
                <Button
                  onClick={validateAccess}
                  w='210px'
                  height='49px'
                  borderRadius='40px'
                  bg="gray.900"
                  color="gray.800"
                  fontWeight='400'

                >
                Setup (Backoffice)
                </Button>

                <Button
                    onClick={onClose}
                    w='210px'
                    height='49px'
                    borderRadius='40px'
                    bg="gray.900"
                    color="gray.800"
                    fontWeight='400'

                >
                Utilizar como cliente
                </Button>
              </Flex>

                {/* <ModalFooter>
                  <Button onClick={onClose}>Voltar</Button>
                </ModalFooter> */}

            </ModalBody>
            </ModalContent>

        </Modal>
  )
}

export { ModalChoiceAccess }
