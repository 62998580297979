import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'

export interface IUserProfile {
  id: string
  email: string
  aiEnabled: boolean
  companyName: string
}
export interface IUserEntity {
  id: string
  createdAt: string
  isActive: boolean
  userEmail: string
  companyId: string
  companyRoleId: string
  companyAreaId: string
  metabaseUser: {
    id: number
    createdAt: string
    isActive: boolean
    firstName: string
    lastName: string
    email: string
    metabaseConfigId: string
    sbGeralGroupId?: number
    sbMarketingGroupId?: number
    sbSalesGroupId?: number
    vGeralGroupId?: number
    vMarketingGroupId?: number
    vSalesGroupId?: number

  }
  companyRole: {
    id: string

    name: string
  }
  companyArea: {
    id: string

    name: string
  }
}

export interface TCreateUserPayload {
  companyId: string
  collaborator: {
    firstName: string
    lastName: string
    email: string
    groups: string[]
    userRoleId: string
    userAreaId: string
  }

}

export interface TDeleteUserPayload {
  companyId: string
  collaboratorId: string
}

export interface TUpdateUserPayload {
  companyId: string
  collaborators: Array<{
    userId: string
    firstName: string
    lastName: string
    email: string
    groups: string[]
    companyRoleId: string
    companyAreaId: string
  }>

}

export class UserApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public getProfile = async (): Promise<Result<IUserProfile>> => {
    try {
      const response = await this.service.get('/profile')
      return Result.ok(response.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public changeCompanyId = async (companyId: string): Promise<Result<void>> => {
    try {
      await this.service.post('/backoffice/user/change-company', { companyId })
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public update = async ({ collaborators, companyId }: TUpdateUserPayload): Promise<Result<void>> => {
    try {
      await this.service.put(`/company/${companyId}/collaborators`, { collaborators })
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public activate = async ({ collaboratorId, companyId }: TDeleteUserPayload): Promise<Result<void>> => {
    try {
      await this.service.put(`/company/${companyId}/collaborator/${collaboratorId}/reactivate`)
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public delete = async ({ collaboratorId, companyId }: TDeleteUserPayload): Promise<Result<void>> => {
    try {
      await this.service.delete(`/company/${companyId}/collaborator/${collaboratorId}`)
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public create = async ({ collaborator, companyId }: TCreateUserPayload): Promise<Result<IUserEntity>> => {
    try {
      const response = await this.service.post(`/company/${companyId}/collaborator`, collaborator)

      return Result.ok(response.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public acceptTermsOfUse = async (macAddress: string): Promise<Result<void>> => {
    try {
      await this.service.patch('/session/terms-of-use', { macAddress })

      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public acceptTermsOfPrivacy = async (macAddress: string): Promise<Result<void>> => {
    try {
      await this.service.patch('/session/terms-of-privacy', { macAddress })

      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
