
import { IIntegrationEntity } from '../../Services/interfaces/integration.interface'
import { IntegrationApiRemoteService } from '../../Services/protocols/service-integration'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ShowIntegrationUseCase implements IUsecase<string, IIntegrationEntity> {
  constructor (private readonly repository: IntegrationApiRemoteService) {}

  public async handle (integrationId: string): Promise<Result<IIntegrationEntity>> {
    try {
      const response = await this.repository.show(integrationId)

      const data = response.getValue()

      if (data === null) {
        return Result.fail(new Error('Empresa não encontrados.'))
      }

      return Result.ok(data)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
