import React, { FC, useState } from 'react'
import { IconButton, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { FiMoreVertical } from 'react-icons/fi'

interface IButtonActionMenuProps {
  options: Array<{
    onClick: (data?: any) => void
    title: string
    disabled?: boolean
  }>
}

const ButtonActionMenu: FC<IButtonActionMenuProps> = ({ options }) => {
  return (
    <Menu>
      <MenuButton as={IconButton} icon={<FiMoreVertical />} variant="ghost" />
      <MenuList>
        {options.map((item, index) => (
          <MenuItem key={index} onClick={item.disabled ? () => {} : item.onClick} opacity={item.disabled ? 0.5 : 1}>
            {item.title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export { ButtonActionMenu }
