import { TCreateCompanyPayload } from '../../Services/interfaces/companies.interface'
import { CompaniesApiRemoteService } from '../../Services/protocols/service-companies'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class CreateCompanyUseCase implements IUsecase<TCreateCompanyPayload, void> {
  constructor (private readonly repository: CompaniesApiRemoteService) {}

  public async handle (data: TCreateCompanyPayload): Promise<Result<void>> {
    try {
      await this.repository.create(data)

      return Result.ok()
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
