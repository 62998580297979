/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Flex, Heading, Table, Tbody, Td, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'

import { Header } from '../../../Components/Header'
import { Loader } from '../../../Components/Loader'
import { useScreenSizes } from '../../../utils/screenSizes'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Can } from '../../../Components/Can'
import { OptionsProps } from '../../../Components/Form/Select'
import { ModalIntegrationsList } from '../../../Components/Modals/ModalIntegrationsList'
import { ToastCustom } from '../../../Components/ToastCustom'

import { ListCompanyUseCase } from '../../../Usecase/Companies/list-companies-usecase'
import { ListIntegrationByCompanyUseCase } from '../../../Usecase/Integrations/list-integration-by-id-usecase'
import { ICompanyEntity } from '../../../Services/interfaces/companies.interface'

interface Props {
  listCompanyUseCase: ListCompanyUseCase
  listIntegrationByCompanyUseCase: ListIntegrationByCompanyUseCase
}

const IntegrationsListPage: FC<Props> = ({ listCompanyUseCase, listIntegrationByCompanyUseCase }) => {
  const [loading, setLoading] = useState(false)
  const [companiesOptions, setCompaniesOptions] = useState<OptionsProps[]>([])
  const [data, setData] = useState<ICompanyEntity[]>([])
  const [selectedItem, setSelectedItem] = useState<ICompanyEntity>()
  const { isMobile } = useScreenSizes()
  const toast = useToast()
  const navigate = useNavigate()

  const { handleSubmit, register, formState } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const handleCreateNewCustomer: SubmitHandler<any> = async (values) => {
    try {
      setLoading(true)
      const { username, password } = values

      //   const listResult = await loginUseCase.handle({ password, username })

      //   if (listResult.isFailure) {
      //     toast({
      //       position: 'top-right',
      //       render: () => <ToastCustom type='fail' title='Erro' description={listResult.error?.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      //     })

      //     return
      //   }

      //   const response = listResult.getValue()

      //   if (response?.token) {
      //     setUserToken(response.token)
      //   }

      //   setNeedsAcceptTermsOfPrivacy(response?.needsAcceptTermsOfPrivacy ?? false)
      //   setNeedsAcceptTermsOfUse(response?.needsAcceptTermsOfUse ?? false)

      //   // if (response?.email && isNalkEmail(response.email)) {
      //   //   setCanChangeAiVersion(true)
      //   // }

      //   if (response?.customer === 'cvc') {
      //     setIsCvc(true)
      //     return navigate('/ai')
      //   }

    //   return isMobile ? navigate('/ai') : navigate('/')
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const loadCompanies = async (): Promise<void> => {
    try {
      setLoading(true)

      // const listResult = await listCompanyUseCase.handle()

      // if (listResult.isFailure) {
      //   toast({
      //     position: 'top-right',
      //     render: () => <ToastCustom type='fail' title='Erro' description={listResult.error?.error ?? 'Erro ao pegar os clientes. Por favor, tente novamente'} />
      //   })

      //   return
      // }

      // const response = listResult.getValue()

      // if (response?.length) {
      //   setData(response)
      // }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadCompanies()
  }, [])

  return (
    <Can page>
      <span>test</span>
      {/* <Flex w="90vw" h="100vh" margin="auto">
        {selectedItem?.id && <ModalIntegrationsList onClose={() => setSelectedItem({} as ICompanyEntity)} listIntegrationByCompanyUseCase={listIntegrationByCompanyUseCase} companyId={selectedItem?.id} companyName={selectedItem?.name} />}
        <Header hideSidebar />
          {loading
            ? <Loader />
            : (
              <Box flex="1" borderRadius={8} bg="white" p="8">
              <Flex mt="8" justify="space-between" align="center">
                <Heading size="lg" fontWeight="normal" >
                  Integrações

                </Heading>

              </Flex>

              <>
                  <Table colorScheme="blue" mt='10'>
                    <Thead>
                      <Tr>

                        <Th textAlign="left">Nome</Th>

                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.map((i) => (
                        <Tr key={i.id}>

                          <Td>

                                <Text fontWeight="bold" onClick={() => setSelectedItem(i)} cursor='pointer' >{i.name}</Text>

                          </Td>

                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                </>
            </Box>
              )}

      </Flex> */}
    </Can>
  )
}

export { IntegrationsListPage }
