/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Button, Flex, Icon, Table, Tbody, Td, Text, Th, Thead, Tr, useTheme, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { AiOutlineDelete } from 'react-icons/ai'
import { FaCheck } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import { RiPencilLine } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import { AlertDialogCustom } from '../../../Components/AlertDialog'
import { Can } from '../../../Components/Can'
import { OptionsProps } from '../../../Components/Form/Select'
import { Header } from '../../../Components/Header'
import { Loader } from '../../../Components/Loader'
import { ModalNewCollaborator } from '../../../Components/Modals/ModalNewCollaborator'
import { ModalNewIntegration } from '../../../Components/Modals/ModalNewIntegration'
import Tabs from '../../../Components/Tabs'
import { ToastCustom } from '../../../Components/ToastCustom'

import { IUserEntity } from '../../../Services/protocols/service-user'
import { ListRolesAndAreasUseCase } from '../../../Usecase/Companies/list-roles-and-areas-usecase'
import { ShowCompanyUseCase } from '../../../Usecase/Companies/show-company-usecase'
import { CreateIntegrationUseCase } from '../../../Usecase/Integrations/create-integration-usecase'
import { ListIntegrationSourcesUseCase } from '../../../Usecase/Integrations/list-integration-sources-usecase'
import { ActivateUserUseCase } from '../../../Usecase/User/activate-user-usecase'
import { CreateUserUseCase } from '../../../Usecase/User/create-user-usecase'
import { DeleteUserUseCase } from '../../../Usecase/User/delete-user-usecase'
import { UpdateUserUseCase } from '../../../Usecase/User/update-user-usecase'
import { useScreenSizes } from '../../../utils/screenSizes'
import { TabContainer, TabContent } from './styles'
import { ButtonActionMenu } from '../../../Components/Form/ButtonActionMenu'
import { SearchInput } from '../../../Components/Form/SearchInput'
import { ICompanyEntity } from '../../../Services/interfaces/companies.interface'
import { SyncTag } from '../../../Components/SyncTag'
import { EIntegrationSyncType, IIntegrationEntity } from '../../../Services/interfaces/integration.interface'
import { DeleteIntegrationUseCase } from '../../../Usecase/Integrations/delete-integration-usecase'
import { SyncIntegrationUseCase } from '../../../Usecase/Integrations/sync-integration-usecase'
import { ShowIntegrationUseCase } from '../../../Usecase/Integrations/show-integration-usecase'
import { UpdateIntegrationUseCase } from '../../../Usecase/Integrations/update-integration-usecase'

enum TabsProps {
  REGISTERS = 'Cadastros',
  INTEGRATIONS = 'Integrações',
}

interface Props {
  listRolesAndAreasUseCase: ListRolesAndAreasUseCase
  showCompanyUseCase: ShowCompanyUseCase
  createUserUseCase: CreateUserUseCase
  deleteUserUseCase: DeleteUserUseCase
  updateUserUseCase: UpdateUserUseCase
  createIntegrationUseCase: CreateIntegrationUseCase
  activateUserUseCase: ActivateUserUseCase
  listIntegrationSourcesUseCase: ListIntegrationSourcesUseCase
  deleteIntegrationUseCase: DeleteIntegrationUseCase
  syncIntegrationUseCase: SyncIntegrationUseCase
  showIntegrationUseCase: ShowIntegrationUseCase
  updateIntegrationUseCase: UpdateIntegrationUseCase
}

export interface ICollaboratorProps {
  id: string
  name: string
  surname: string
  email: string
  position: string
  area: string
  permissions: Array<{
    sb_geral: string
    sb_mkt: string
    sb_sale: string
    v_geral: string
    v_mkt: string
    v_sale: string
  }>
}

const PanelCustomerPage: FC<Props> = ({ listRolesAndAreasUseCase, showCompanyUseCase, createUserUseCase, deleteUserUseCase, updateUserUseCase, createIntegrationUseCase, activateUserUseCase, listIntegrationSourcesUseCase, deleteIntegrationUseCase, syncIntegrationUseCase, showIntegrationUseCase, updateIntegrationUseCase }) => {
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState<any>(
    'Cadastros' as `${TabsProps}`
  )
  const [showNewCollaboratorModal, setShowNewCollaboratorModal] = useState(false)
  const [showNewIntegrationModal, setShowNewIntegrationModal] = useState(false)
  const [collaborators, setCollaborators] = useState<ICollaboratorProps[]>([])
  const { isMobile } = useScreenSizes()
  const toast = useToast()
  const navigate = useNavigate()
  const [data, setData] = useState<ICompanyEntity>()

  const [showModalConfirmation, setShowModalConfirmation] = useState<{ show: boolean; collaboradorId: string }>({ show: false, collaboradorId: '' })
  const [positionOptions, setPositionOptions] = useState<OptionsProps[]>()
  const [areasOptions, setAreasOptions] = useState<OptionsProps[]>()
  const [selectItem, setSelectItem] = useState<IUserEntity>()
  const [selectItemIntegration, setSelectItemIntegration] = useState<IIntegrationEntity>()
  const [selectEditItemIntegration, setSelectEditItemIntegration] = useState<IIntegrationEntity>()
  const [selectItemToEdit, setSelectItemToEdit] = useState<IUserEntity>()
  const params = useParams()
  const theme = useTheme()

  const { handleSubmit, register, formState } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const handleAddCollaborator = (): void => {
    const payload: ICollaboratorProps = {
      id: uuid(),
      name: '',
      surname: '',
      email: '',
      area: '',
      position: '',
      permissions: []
    }

    setCollaborators(prev => [payload, ...prev])
  }

  const handleDeleteCollaborator = async () => {
    try {
      const filtered = collaborators.filter(i => i.id !== showModalConfirmation.collaboradorId)

      setCollaborators([...filtered])

      setShowModalConfirmation({ show: false, collaboradorId: '' })
    } catch (error) {

    }
  }

  const listRolesAndAreas = async () => {
    try {
      const responseResult = await listRolesAndAreasUseCase.handle()

      if (responseResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={responseResult.error?.error ?? 'Erro ao buscar as áreas. Por favor, tente novamente'} />
        })

        return
      }

      const value = responseResult.getValue()

      const areas = value?.areas?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })

      if (areas?.length) {
        setAreasOptions(areas)
      }

      const roles = value?.roles?.map((i: any) => {
        return {
          value: i.id,
          label: i.name
        }
      })

      if (roles?.length) {
        setPositionOptions(roles)
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro. Por favor, tente novamente'} />
      })
    }
  }

  const loadCompanyData = async (id: string) => {
    try {
      const showResult = await showCompanyUseCase.handle(id)

      if (showResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={showResult.error?.error ?? 'Erro ao buscar o cliente. Por favor, tente novamente'} />
        })

        return
      }

      const value = showResult.getValue()

      if (value?.id) {
        const normalize = {
          ...value
          // users: value?.users?.filter(i => !!i.isActive)
        }
        setData(normalize)
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const closeModal = (): void => {
    setSelectItem({} as IUserEntity)
  }

  const hanleDelete = async (): Promise<void> => {
    setLoading(true)
    try {
      const deleteResult = selectItem?.isActive ? await deleteUserUseCase.handle({ companyId: String(params.id), collaboratorId: String(selectItem?.id) }) : await activateUserUseCase.handle({ companyId: String(params.id), collaboratorId: String(selectItem?.id) })

      if (deleteResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={deleteResult.error?.error ?? 'Erro ao deletar o colaborador. Por favor, tente novamente'} />
        })

        return
      }

      loadCompanyData(String(params.id))
      closeModal()
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const hanleDeleteIntegration = async (): Promise<void> => {
    setLoading(true)
    try {
      const deleteResult = await deleteIntegrationUseCase.handle(String(selectItemIntegration?.id))

      if (deleteResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={deleteResult.error?.error ?? 'Erro ao deletar a integração. Por favor, tente novamente'} />
        })

        return
      }
      setSelectItemIntegration({} as IIntegrationEntity)
      loadCompanyData(String(params.id))
      closeModal()
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const hanleSyncIntegration = async (integrationId: string): Promise<void> => {
    setLoading(true)
    try {
      const syncResult = await syncIntegrationUseCase.handle(integrationId)

      if (syncResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={syncResult.error?.error ?? 'Erro ao iniciar a sincronização. Por favor, tente novamente'} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description='Sincronização iniciada.' />
      })

      loadCompanyData(String(params.id))
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    listRolesAndAreas()

    if (!params?.id) return

    loadCompanyData(params?.id)
  }, [params])

  const handleShowIntegration = (i: IIntegrationEntity) => {
    setSelectEditItemIntegration(i)
    setShowNewIntegrationModal(true)
  }

  return (
    <Can page>
      <Flex w="100vw" >
          {loading && <Loader />}

          {!!selectItem?.id && (
            <AlertDialogCustom
              title={selectItem?.isActive ? 'Desativar usuário' : 'Ativar usuário' }
              isOpen
              cancelFunction={closeModal}
              nextFunction={hanleDelete}
            />
          )}

          {!!selectItemIntegration?.id && (
            <AlertDialogCustom
              title='Remover Integração'
              isOpen
              cancelFunction={() => setSelectItemIntegration({} as IIntegrationEntity)}
              nextFunction={hanleDeleteIntegration}
            />
          )}

          {showModalConfirmation?.show && (
            <AlertDialogCustom
              title="Remover Usuário"
              isOpen
              cancelFunction={() => setShowModalConfirmation({ show: false, collaboradorId: '' })}
              nextFunction={handleDeleteCollaborator}
            />
          )}

          { showNewIntegrationModal && <ModalNewIntegration
            listIntegrationSourcesUseCase={listIntegrationSourcesUseCase}
            onClose={() => {
              setShowNewIntegrationModal(false)
              setSelectEditItemIntegration({} as IIntegrationEntity)
            }}
            onSubmit={() => {}}
            roles={positionOptions ?? []}
            areas={areasOptions ?? []}
            createIntegrationUseCase={createIntegrationUseCase}
            updateUserUseCase={updateUserUseCase}
            companyId={String(params?.id)}
            cbFunction={() => {
              loadCompanyData(String(params?.id))
              setShowNewIntegrationModal(false)
              setSelectEditItemIntegration({} as IIntegrationEntity)
            }}
            user={selectItemToEdit}
            integration={selectEditItemIntegration}
            showIntegrationUseCase={showIntegrationUseCase}
            updateIntegrationUseCase={updateIntegrationUseCase}
          />
          }

          {showNewCollaboratorModal && <ModalNewCollaborator
            onClose={() => setShowNewCollaboratorModal(false)}
            onSubmit={() => {}}
            roles={positionOptions ?? []}
            areas={areasOptions ?? []}
            createUserUseCase={createUserUseCase}
            updateUserUseCase={updateUserUseCase}
            companyId={String(params?.id)}
            cbFunction={() => {
              loadCompanyData(String(params?.id))
              setShowNewCollaboratorModal(false)
            }}
            user={selectItemToEdit}

          />}

          <Header hideSidebar />

          <Flex w="100%" flexDir="column" bg="white" padding='50px' paddingTop='25px'>
            <Flex gap='20px' alignItems='center' justifyContent='space-between'>
              <Box>
                <Button
                onClick={() => navigate('/backoffice/select-customer')}
                w='210px'
                height='49px'
                borderRadius='40px'
                bg="black"
                color="gray.800"
                fontWeight='400'
                isLoading={formState.isSubmitting}
                mb='10px'
                >
                Trocar Empresa
              </Button>

              </Box>

              <Text color="gray.900" fontSize='25px' fontWeight="700" lineHeight='35px' margin={0} fontFamily={theme.fonts.body}>{data?.name}</Text>

              <Box w='210px' />
            </Flex>

              <TabContainer>
                <Tabs
                  tabs={['Cadastros', 'Integrações']}
                  activeTab={activeTab}
                  onSelectTab={(tab: string) =>
                    setActiveTab(tab as `${TabsProps}`)
                  }
                />
                <TabContent>
                  {activeTab === 'Cadastros' && (
                    <Box padding='5px'>
                      <Flex justifyContent='flex-end' w='100%' padding='0px 0px 20px 40px'>
                          <Button
                              onClick={() => setShowNewCollaboratorModal(true)}
                              w='210px'
                              height='49px'
                              borderRadius='40px'
                              bg="gray.900"
                              color="gray.800"
                              fontWeight='400'
                              isLoading={formState.isSubmitting}
                          >
                          Adicionar Usuário
                          </Button>
                      </Flex>

                      {!data?.users?.length && (
                          <Flex flex={1} alignItems='center' justifyContent='center' height='400px'>
                            <Text>Nenhum usuário cadastrado.</Text>
                          </Flex>
                      )}

                    {!!data?.users?.length && (

                      <Table colorScheme="blue" mt="20px" marginBottom='50px'>
                        <Thead>
                            <Tr>

                                <Th textAlign="left" fontWeight="700">Nome</Th>
                                {/* <Th textAlign="left" fontWeight="700">Status</Th> */}
                                <Th textAlign="center" fontWeight="700">E-mail</Th>
                                <Th textAlign="center" fontWeight="700">Área</Th>
                                <Th textAlign="center" fontWeight="700">Cargo</Th>
                                <Th textAlign="center" fontWeight="700">SB_GERAL</Th>
                                <Th textAlign="center" fontWeight="700">SB_MKT</Th>
                                <Th textAlign="center" fontWeight="700">SB_VENDAS</Th>
                                <Th textAlign="center" fontWeight="700">V_GERAL</Th>
                                <Th textAlign="center" fontWeight="700">V_MKT</Th>
                                <Th textAlign="center" fontWeight="700">V_VENDAS</Th>
                                <Th w="8"></Th>
                            </Tr>
                        </Thead>

                        { data?.users?.map((i) =>
                          (

                              <Tbody key={i?.metabaseUser?.id}>
                              <Tr bg="green.dark">
                                  <Td><Text fontWeight="700" textAlign="center" >{i?.metabaseUser?.firstName}</Text></Td>
                                  <Td><Text fontWeight="700" textAlign="center" fontSize='15px' >{i?.metabaseUser?.email}</Text></Td>
                                  <Td><Text fontWeight="700" textAlign="center" fontSize='15px' >{i?.companyArea?.name || 'Não informado'} </Text></Td>
                                  <Td><Text fontWeight="700" textAlign="center" fontSize='15px' >{i?.companyRole?.name || 'Não informado'}</Text></Td>
                                  <Td><Flex justifyContent='center' fontWeight="700" textAlign="center" >{i?.metabaseUser?.sbGeralGroupId ? <FaCheck /> : <IoMdClose />} </Flex></Td>
                                  <Td><Flex fontWeight="700" justifyContent='center' textAlign="center" >{i?.metabaseUser?.sbMarketingGroupId ? <FaCheck /> : <IoMdClose />}</Flex></Td>
                                  <Td><Flex fontWeight="700" justifyContent='center' textAlign="center" >{i?.metabaseUser?.sbSalesGroupId ? <FaCheck /> : <IoMdClose />}</Flex></Td>
                                  <Td><Flex fontWeight="700" justifyContent='center' textAlign="center" >{i?.metabaseUser?.vGeralGroupId ? <FaCheck /> : <IoMdClose />}</Flex></Td>
                                  <Td><Flex fontWeight="700" justifyContent='center' textAlign="center" >{i?.metabaseUser?.vMarketingGroupId ? <FaCheck /> : <IoMdClose />}</Flex></Td>
                                  <Td><Flex fontWeight="700" justifyContent='center' textAlign="center" >{i?.metabaseUser?.vSalesGroupId ? <FaCheck /> : <IoMdClose />}</Flex></Td>
                                  <Td>
                                    <Flex>
                                    <ButtonActionMenu options={[
                                      {
                                        title: 'Editar',
                                        onClick: () => {
                                          setSelectItemToEdit(i)
                                          setShowNewCollaboratorModal(true)
                                        }
                                      },
                                      {
                                        title: i?.isActive ? 'Desativar' : 'Ativar',
                                        onClick: () => setSelectItem(i)
                                      }
                                    ]} />

                                      {/* <Button
                                        onClick={() => {
                                          setSelectItemToEdit(i)
                                          setShowNewCollaboratorModal(true)
                                        }}
                                        size="sm"
                                        fontSize="sm"
                                        mr="2"
                                        bg="wine.200"
                                        colorScheme="wine"
                                        leftIcon={
                                          <Icon as={RiPencilLine} fontSize="16" />
                                        }
                                      >
                                        Editar
                                      </Button>

                                      <Button
                                        as="button"
                                        onClick={() => setSelectItem(i)}
                                        size="sm"
                                        fontSize="sm"
                                        colorScheme={i?.isActive ? 'red' : 'green'}
                                        leftIcon={
                                          <Icon as={AiOutlineDelete} fontSize="16" />
                                        }
                                      >
                                        {i?.isActive ? 'Desativar' : 'Ativar'}
                                      </Button> */}
                                      </Flex>
                                  </Td>

                              </Tr>

                              </Tbody>
                          )

                        )}
                          </Table>
                    )}
                    </Box>
                  )}

                  {activeTab === 'Integrações' && (
                  <Box padding='5px'>
                    <Box padding='5px'>
                      <Flex justifyContent='flex-end' w='100%' padding='0px 0px 20px 40px'>
                          <Button
                              onClick={() => setShowNewIntegrationModal(true)}
                              w='210px'
                              height='49px'
                              borderRadius='40px'
                              bg="gray.900"
                              color="gray.800"
                              fontWeight='400'
                              isLoading={formState.isSubmitting}
                          >
                          Adicionar Integração
                          </Button>
                      </Flex>

                    {!data?.integrations?.length && (
                      <Flex flex={1} alignItems='center' justifyContent='center' height='400px'>
                        <Text>Nenhuma integração cadastrada.</Text>
                      </Flex>
                    )}

                    {!!data?.integrations?.length &&
                      <Table colorScheme="blue" mt="20px">
                        <Thead>
                            <Tr>

                                <Th textAlign="left" fontWeight="700">Integração</Th>
                                <Th w="24"></Th>
                            </Tr>
                        </Thead>

                        {data?.integrations?.map((i: IIntegrationEntity, index: number) =>
                          (

                            <Tbody key={i.id}>
                            <Tr bg="green.dark">
                                <Td><Text fontWeight="700" textAlign="left" >{i?.type}</Text></Td>

                                <Td w={12}>
                                  <Flex gap='20px' w='300px' justifyContent='flex-end'>
                                    <SyncTag status={i.syncStage} errorMessage={i.syncStageMessage} />

                                    <ButtonActionMenu options={[
                                      {
                                        title: 'Sincronizar',
                                        onClick: async () => await hanleSyncIntegration(i.id),
                                        disabled: [EIntegrationSyncType.SYNCED, EIntegrationSyncType.SYNCING].includes(i.syncStage as EIntegrationSyncType)
                                      },
                                      {
                                        title: 'Editar',
                                        onClick: () => handleShowIntegration(i)

                                      },
                                      {
                                        title: 'Excluir',
                                        onClick: () => setSelectItemIntegration(i)

                                      }
                                    ]} />

                                  </Flex>
                                </Td>

                            </Tr>

                            </Tbody>
                          ))}

                      </Table>
                      }
                    </Box>
                  </Box>
                  )}

                </TabContent>
              </TabContainer>

          </Flex>

      </Flex>
    </Can>
  )
}

export { PanelCustomerPage }
