
import { IAirbyteIntegrationSource } from '../../Services/interfaces/integration.interface'
import { IntegrationApiRemoteService } from '../../Services/protocols/service-integration'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ListIntegrationSourcesUseCase implements IUsecase<string, IAirbyteIntegrationSource[]> {
  constructor (private readonly repository: IntegrationApiRemoteService) {}

  public async handle (): Promise<Result<IAirbyteIntegrationSource[]>> {
    try {
      const response = await this.repository.listAirbyteSources()

      const data = response.getValue()

      if (data === null) {
        return Result.fail(new Error('Sources não encontradas.'))
      }

      return Result.ok(data)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
