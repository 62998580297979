import { AxiosInstance } from 'axios'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IAirbyteIntegrationSource, IIntegrationEntity, TCreateIntegrationsPayload, TUpdateIntegrationsPayload } from '../interfaces/integration.interface'

export class IntegrationApiRemoteService {
  constructor (private readonly service: AxiosInstance) {}

  public listAirbyteSources = async (): Promise<Result<IAirbyteIntegrationSource[]>> => {
    try {
      const respose = await this.service.get('/integrations/available-airbyte-sources')
      return Result.ok(respose.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public getByCompanyId = async (companyId: string): Promise<Result<IIntegrationEntity[]>> => {
    try {
      const respose = await this.service.get(`/integrations/company/${companyId}`)
      return Result.ok(respose.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public show = async (integrationId: string): Promise<Result<IIntegrationEntity>> => {
    try {
      const respose = await this.service.get(`/integrations/${integrationId}`)
      return Result.ok(respose.data)
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public create = async (data: TCreateIntegrationsPayload): Promise<Result<void>> => {
    try {
      await this.service.post('/integrations', data)
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public delete = async (integrationId: string): Promise<Result<void>> => {
    try {
      await this.service.delete(`/integrations/${integrationId}`)
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public sync = async (integrationId: string): Promise<Result<void>> => {
    try {
      await this.service.post(`/integrations/${integrationId}/re-sync`)
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }

  public update = async ({ integrationId, extra }: TUpdateIntegrationsPayload): Promise<Result<void>> => {
    try {
      await this.service.put(`/integrations/${integrationId}`, { extra })
      return Result.ok()
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}
