import { Box, Button, Flex, Text, theme, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'

import { Loader } from '../../../Components/Loader'
import { useScreenSizes } from '../../../utils/screenSizes'

import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Checkbox } from '../../../Components/Form/Checkbox'
import { Input } from '../../../Components/Form/Input'
import { ToastCustom } from '../../../Components/ToastCustom'
import { CreateCompanyUseCase } from '../../../Usecase/Companies/create-company-usecase'
import { Can } from '../../../Components/Can'
import { InputMaskCustom } from '../../../Components/Form/InputMask'
import { ShowCompanyUseCase } from '../../../Usecase/Companies/show-company-usecase'
import { UpdateCompanyUseCase } from '../../../Usecase/Companies/update-company-usecase'

interface Props {
  createCompanyUseCase: CreateCompanyUseCase
  showCompanyUseCase: ShowCompanyUseCase
  updateCompanyUseCase: UpdateCompanyUseCase
}

const NewCustomerPage: FC<Props> = ({ createCompanyUseCase, showCompanyUseCase, updateCompanyUseCase }) => {
  const [loading, setLoading] = useState(true)
  const { isMobile } = useScreenSizes()
  const toast = useToast()
  const navigate = useNavigate()
  const params = useParams()

  const { handleSubmit, register, formState, setValue } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const handleCreateNewCustomer: SubmitHandler<any> = async (values) => {
    try {
      setLoading(true)
      const { name, document } = values

      const payload = {
        clientName: name,
        collectionName: name,
        marketingEnabled: values?.marketingEnabled,
        salesEnabled: values?.salesEnabled,
        document

      }

      const createResult = params?.id ? await updateCompanyUseCase.handle({ compaynId: String(params.id), ...payload }) : await createCompanyUseCase.handle(payload)

      if (createResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={createResult.error?.error ?? 'Erro na operação. Por favor, tente novamente'} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description={params?.id ? 'Cliente Atualizado' : 'Cliente cadastrado'} />
      })

      navigate('/backoffice/companies')
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const load = async (id: string) => {
    try {
      const showResult = await showCompanyUseCase.handle(id)

      if (showResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={showResult.error?.error ?? 'Erro ao buscar o cliente. Por favor, tente novamente'} />
        })

        return
      }

      const value = showResult.getValue()

      if (value?.id) {
        setValue('name', value?.name)
        if (value?.document) {
          setValue('document', value.document)
        }

        if (value?.salesEnabled) {
          setValue('salesEnabled', true)
        }

        if (value?.marketingEnabled) {
          setValue('marketingEnabled', true)
        }
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (params?.id) {
      load(params?.id)
    } else {
      setLoading(false)
    }
  }, [params])

  return (
    <Can page>
      <Flex w="100vw" h="100vh" alignItems="center" justifyContent="center">
          {loading
            ? <Loader />
            : (
            <Flex flexDirection={isMobile ? 'column' : 'row'} flex={1}>
                  <Flex
                  as="form"
                  w="100%"
                  maxWidth='534px'
                  height='537px'
                  boxShadow='-4px 7px 31px 0px #00000024'
                  flexDir="column"
                  borderRadius={18}
                  onSubmit={handleSubmit(handleCreateNewCustomer)}
                  bg="white"
                  margin='auto'
                  alignItems='center'
                  justifyContent='center'

              >

                  <Text color="gray.900" fontWeight="700" fontSize="36px" margin={0} fontFamily={theme.fonts.body}>{params?.id ? 'Edição de cliente' : 'Cadastro de novo cliente'}</Text>
                  <Box marginTop='35px' w='100%' maxWidth='277px'>
                  <Input
                      placeholder="Digite o nome do cliente"
                      type="text"
                      label="Nome"
                      labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                      style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                      _placeholder={{ color: 'gray.third' }}
                      {...register('name')}
                  />

                    <InputMaskCustom
                      placeholder="Digite o CNPJ do cliente"
                      type="text"
                      label="CNPJ"
                      mask='99.999.999/9999-99'
                      labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                      style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                      _placeholder={{ color: 'gray.third' }}
                      {...register('document')}
                    />

                  <Checkbox
                    label='Marketing'
                    {...register('marketingEnabled')}
                  />

                  <Checkbox
                    label='Vendas'
                    {...register('salesEnabled')}
                  />

                  </Box>

                  <Button
                      type="submit"
                      w='278px'
                      height='49px'
                      borderRadius='40px'
                      mt="36px"
                      bg="gray.900"
                      color="gray.800"
                      fontWeight='400'
                      isLoading={formState.isSubmitting}
                  >
                  {params?.id ? 'Editar' : 'Cadastrar'}
                  </Button>
              </Flex>

            </Flex>
              )}

      </Flex>
    </Can>
  )
}

export { NewCustomerPage }
