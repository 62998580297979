import React, { useState, useEffect, forwardRef, ForwardRefRenderFunction } from 'react'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'

interface SearchInputProps {
  onSearch: (data: any) => void
}

const SearchInputBase: ForwardRefRenderFunction<HTMLInputElement, SearchInputProps> = (
  { onSearch, ...rest },
  ref
) => {
  const [value, setValue] = useState('')
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value
    setValue(newValue)

    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setTypingTimeout(
      setTimeout(() => {
        onSearch(newValue.length >= 3 ? newValue : '')
      }, 500)
    )
  }

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <FiSearch color="gray.300" />
      </InputLeftElement>
      <Input
        ref={ref}
        value={value}
        onChange={handleChange}
        placeholder="Buscar..."
        {...rest}
      />
    </InputGroup>
  )
}

export const SearchInput = forwardRef(SearchInputBase)
