
export interface TUpdateIntegrationsPayload {
  integrationId: string
  extra?: {
    [key: string]: string
  }
}

export interface TCreateIntegrationsPayload {
  companyId: string
  type: string
  extra?: {
    [key: string]: string
  }
}

// export interface IIntegrationEntity {
//   id: string
//   createdAt: string
//   isActive: boolean
//   companyId: string
//   type: string
//   token?: string
//   clientId?: string
//   clientSecret?: string
//   refreshToken?: string
//   login?: string
//   password?: string
// }

export type TIntegrationSyncStage = 'START' | 'SYNCING' | 'SYNCED' | 'ERROR'

export enum EIntegrationSyncType {
  START = 'START',
  SYNCING = 'SYNCING',
  SYNCED = 'SYNCED',
  ERROR = 'ERROR'
}

export interface IIntegrationEntity {
  id: string
  createdAt: string
  updatedAt: string
  isActive: boolean
  companyId: string
  type: string
  syncStage: TIntegrationSyncStage
  syncStageMessage: string
  extra: {
    [key: string]: string
  }
  airbyteSourceId: string
  airbyteDestinationId: string
  airbyteConnectionId: string
  airbyteConnectionStreamsSelected: string[]
}

export interface IAirbyteIntegrationSource {
  sourceType: string
  sourceName: string
  credentials: Array<{
    inputName: string
    inputKeyName: string
    hint?: string
  }>

}
