import { Box, Button, Flex, theme, useToast, Text, Table, Tabs, TabsProps, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'

import { Chatbot } from '../../../Components/Chatbot'
import { Header } from '../../../Components/Header'
import { Loader } from '../../../Components/Loader'
import { useScreenSizes } from '../../../utils/screenSizes'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { ModalSignOut } from '../../../Components/Modals/ModalSignOut'
import PrivacyModal from '../../../Components/Modals/PrivacyModal'
import TermsModal from '../../../Components/Modals/TermsModal'
import { ToastCustom } from '../../../Components/ToastCustom'
import { useChat } from '../../../Hooks/useChat'
import { CreateFavoritesUseCase } from '../../../Usecase/Chat/create-favorite-usecase'
import { CreateThreadUseCase } from '../../../Usecase/Chat/create-thread-usecase'
import { DeleteFavoritesUseCase } from '../../../Usecase/Chat/delete-favorite-usecase'
import { ListFavoritesUseCase } from '../../../Usecase/Chat/list-favorites-usecase'
import { ListSuggestionUseCase } from '../../../Usecase/Chat/list-suggestion-usecase'
import { RatingMessageUseCase } from '../../../Usecase/Chat/rating-message-usecase'
import { SendMessageUseCase } from '../../../Usecase/Chat/send-message-usecase'
import { Input } from '../../../Components/Form/Input'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Checkbox } from '../../../Components/Form/Checkbox'
import { OptionsProps, Select } from '../../../Components/Form/Select'
import { ListCompanyUseCase } from '../../../Usecase/Companies/list-companies-usecase'
import { Can } from '../../../Components/Can'
import { ChangeCompanyIdUseUseCase } from '../../../Usecase/User/change-company-id-usecase'
import { FaCheck } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import { ButtonActionMenu } from '../../../Components/Form/ButtonActionMenu'
import { SearchInput } from '../../../Components/Form/SearchInput'
import { TabContainer, TabContent } from '../PanelCustomer/styles'
import { ICompanyEntity, ICompanyListPayload } from '../../../Services/interfaces/companies.interface'
import { Pagination } from '../../../Components/Pagination'

interface DataProps {
  users: number
  videos: number
}

interface Props {
  listCompanyUseCase: ListCompanyUseCase
  changeCompanyIdUseUseCase: ChangeCompanyIdUseUseCase
}

const SelectCustomerPage: FC<Props> = ({ listCompanyUseCase, changeCompanyIdUseUseCase }) => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const { isMobile } = useScreenSizes()
  const [companiesOptions, setCompaniesOptions] = useState<ICompanyEntity[]>([])
  const toast = useToast()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [searchParams] = useSearchParams()
  const [total, setTotal] = useState(0)
  const { userAllowedToChangeCustomer, currentCustomerName, companyId } = useChat()
  const [customerSelected, setCustomerSelected] = useState('')

  const selectCustomerForChat = !!searchParams.get('fromChat')

  const { handleSubmit, register, formState } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const changeCompanyId = async (companyId: string): Promise<void> => {
    try {
      setLoading(true)

      const changeResult = await changeCompanyIdUseUseCase.handle(companyId)

      if (changeResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={changeResult.error?.error ?? 'Erro ao trocar o cliente. Por favor, tente novamente'} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description='Usuário alterado, logar novamente.' />
      })
      navigate('/signin')
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  const handleNext = async () => {
    if (!customerSelected) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description='Selecione o cliente' />
      })
      return
    }

    if (selectCustomerForChat && !userAllowedToChangeCustomer) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description='Você não possui permissão' />
      })
      return
    }

    if (selectCustomerForChat) {
      if (customerSelected !== companyId) {
        // está tentando trocar de usuario
        await changeCompanyId(customerSelected)

        return
      }

      navigate('/')
      return
    }

    navigate(`/backoffice/panel-customer/${String(customerSelected)}`)
  }

  const loadCompanies = async ({ page, search }: ICompanyListPayload): Promise<void> => {
    try {
      setLoading(true)

      const listResult = await listCompanyUseCase.handle({ page, search })

      if (listResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={listResult.error?.error ?? 'Erro ao pegar os clientes. Por favor, tente novamente'} />
        })

        return
      }

      const response = listResult.getValue()

      if (response?.data?.length) {
        setCompaniesOptions([...response?.data])
        setTotal(response?.total ?? 0)
        // setPage(page)
      } else {
        setCompaniesOptions([])
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void loadCompanies({ page })
  }, [page])

  useEffect(() => {
    if (customerSelected) {
      void handleNext()
    }
  }, [customerSelected])

  return (
    <Can page>
      <Flex w="100vw" h="100vh" direction='column'>
          {loading && <Loader />}
          <Header hideSidebar />

        <Flex w="100%" flexDir="column" bg="white" padding='50px' paddingTop='5px' paddingBottom='30px'>
          <Box >
            <Text color="gray.900" fontWeight="700" lineHeight='35px' w={selectCustomerForChat ? '70%' : '100%'} fontSize={selectCustomerForChat ? '29px' : '36px'} margin={0} fontFamily={theme.fonts.body}>{selectCustomerForChat ? 'Você quer acessar a IA com qual cliente?' : 'Seleção de cliente'}</Text>

          <Box marginTop='20px'>

            <SearchInput onSearch={(search) => { void loadCompanies({ page: 1, search }) }} />
          </Box>

          </Box>

        </Flex>

        <Box padding='50px' paddingTop='0px'>
          <Table colorScheme="blue">
            <Thead>
                <Tr>
                    <Th textAlign="left" fontWeight="700">Cliente</Th>
                    <Th w="8"></Th>
                </Tr>
            </Thead>

              { companiesOptions?.map((i) =>
                (

                    <Tbody key={i?.id}>
                    <Tr bg="green.dark">
                        <Td><Text fontWeight="700" >{i?.name}</Text></Td>

                        <Td>
                          <Flex>
                          <Button
                              type="submit"
                              w='278px'
                              height='49px'
                              borderRadius='40px'

                              bg="gray.900"
                              color="gray.800"
                              fontWeight='400'
                              onClick={() => setCustomerSelected(i?.id)}
                          >
                          Selecionar
                          </Button>

                            {/* <Button
                              onClick={() => {
                                setSelectItemToEdit(i)
                                setShowNewCollaboratorModal(true)
                              }}
                              size="sm"
                              fontSize="sm"
                              mr="2"
                              bg="wine.200"
                              colorScheme="wine"
                              leftIcon={
                                <Icon as={RiPencilLine} fontSize="16" />
                              }
                            >
                              Editar
                            </Button>

                            <Button
                              as="button"
                              onClick={() => setSelectItem(i)}
                              size="sm"
                              fontSize="sm"
                              colorScheme={i?.isActive ? 'red' : 'green'}
                              leftIcon={
                                <Icon as={AiOutlineDelete} fontSize="16" />
                              }
                            >
                              {i?.isActive ? 'Desativar' : 'Ativar'}
                            </Button> */}
                            </Flex>
                        </Td>

                    </Tr>

                    </Tbody>
                )

              )}
          </Table>

        {!loading && <Pagination
            totalCountOfRegisters={total}
            currentPage={page}
            onPageChange={setPage}
          />
        }
        </Box>

      </Flex>
    </Can>
  )
}

export { SelectCustomerPage }
