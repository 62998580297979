import React from 'react'
import { Button } from '@chakra-ui/react'

interface PaginationItemProps {
  isCurrent?: boolean
  number: number
  onPageChange: (page: number) => void
}

export function PaginationItem ({ isCurrent = false, onPageChange, number }: PaginationItemProps) {
  if (isCurrent) {
    return (
            <Button
                size="sm"
                fontSize="xs"
                w="4"
                backgroundColor='black'

                disabled
                _disabled={{
                  bgColor: 'black',
                  cursor: 'default'

                }}
            >{number}</Button>
    )
  }

  return (
        <Button
        size="sm"
        fontSize="xs"
        w="4"

        bg="gray.700"
        _hover={{
          bg: 'green.400',
          color: 'black'
        }}
        color="gray.50"
        onClick={() => onPageChange(number)}
         >{number}</Button>
  )
}
