
import { TUpdateIntegrationsPayload } from '../../Services/interfaces/integration.interface'
import { IntegrationApiRemoteService } from '../../Services/protocols/service-integration'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class UpdateIntegrationUseCase implements IUsecase<TUpdateIntegrationsPayload, void> {
  constructor (private readonly repository: IntegrationApiRemoteService) {}

  public async handle (payload: TUpdateIntegrationsPayload): Promise<Result<void>> {
    try {
      await this.repository.update(payload)

      return Result.ok()
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
