import { Box, Flex, Image, Radio, RadioGroup, useToast } from '@chakra-ui/react'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { ArrowLeftIcon } from '../../Assets/IconsComponents/ArrowLeft'
import BrandArrow from '../../Assets/brand-arrow.png'
import Brand from '../../Assets/brand-image.png'
import { Loader } from '../../Components/Loader'
import { Welcome } from '../../Components/Welcome'
import { Chatbot } from '../../Components/Chatbot'
import { Logo } from '../../Components/Logo'
import { Header } from '../../Components/Header'
import { useScreenSizes } from '../../utils/screenSizes'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { CreateThreadUseCase } from '../../Usecase/Chat/create-thread-usecase'
import { useChat } from '../../Hooks/useChat'
import { SendMessageUseCase } from '../../Usecase/Chat/send-message-usecase'
import { RatingMessageUseCase } from '../../Usecase/Chat/rating-message-usecase'
import { Tabs } from '../../Components/Chatbot/Tabs'
import { ListSuggestionUseCase } from '../../Usecase/Chat/list-suggestion-usecase'
import { ListFavoritesUseCase } from '../../Usecase/Chat/list-favorites-usecase'
import { CreateFavoritesUseCase } from '../../Usecase/Chat/create-favorite-usecase'
import { DeleteFavoritesUseCase } from '../../Usecase/Chat/delete-favorite-usecase'
import { ToastCustom } from '../../Components/ToastCustom'
import { MdLogin } from 'react-icons/md'
import { ModalSignOut } from '../../Components/Modals/ModalSignOut'
import TermsModal from '../../Components/Modals/TermsModal'
import PrivacyModal from '../../Components/Modals/PrivacyModal'
import { ModalChoiceAccess } from '../../Components/ModalChoiceAccess'
import { ProfileUserUseCase } from '../../Usecase/User/profile-user-usecase'
import { ShowCompanyUseCase } from '../../Usecase/Companies/show-company-usecase'

interface DataProps {
  users: number
  videos: number
}

interface Props {
  createThreadUseCase: CreateThreadUseCase
  sendMessageUseCase: SendMessageUseCase
  ratingMessageUseCase: RatingMessageUseCase
  listSuggestionUseCase: ListSuggestionUseCase
  listFavoritesUseCase: ListFavoritesUseCase
  createFavoritesUseCase: CreateFavoritesUseCase
  deleteFavoritesUseCase: DeleteFavoritesUseCase
  profileUserUseCase: ProfileUserUseCase
  showCompanyUseCase: ShowCompanyUseCase
}

const attemptToCreateThreadIdLimit = 4

const ChatbotPage: FC<Props> = ({ showCompanyUseCase, profileUserUseCase }) => {
  const { isMobile } = useScreenSizes()

  const toast = useToast()
  const navigation = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [searchParams] = useSearchParams()
  const { handlePreFillMessage, preFillMessage, needsAcceptTermsOfPrivacy, needsAcceptTermsOfUse, setModalChoice, modalChoice, aiEnabled } = useChat()

  const { threadId, threadIsLoading, chatLoading, errorLimitAttemptThreadId, favorites, handleCreateFavorite, handleDeleteFavorite, handleListFavorites, handleListSuggestions, handleRatingMessage, handleSendMessage, isLoading, messages, suggestions, handleClearChat, start, setStart } = useChat()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleListSuggestions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleListFavorites()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAcceptTermsOfUse = (): void => {
    console.log('accept terms of use')
  }

  const getProfile = async (): Promise<void> => {
    try {
      const profileResult = await profileUserUseCase.handle()

      if (profileResult.isFailure) {
        console.log('erro ao gerar token metabase: ', profileResult?.error?.error)
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={profileResult.error?.error ?? 'Erro ao buscar o perfil. Por favor, tente novamente'} />
        })

        return
      }

      const response = profileResult.getValue()

      if (!response?.aiEnabled) {
        navigation('/')
      }
    } catch (error) {
      console.log('error updateMetabaseToken: ', error)
    }
  }

  useEffect(() => {
    getProfile()
  }, [])

  return (
    <Flex flexDirection={isMobile ? 'column' : 'row'} flex={1}>
       {openModal && <ModalSignOut onClose={() => setOpenModal(false)} /> }
       {needsAcceptTermsOfPrivacy && <TermsModal handleSubmit={handleAcceptTermsOfUse} /> }
       {needsAcceptTermsOfUse && !needsAcceptTermsOfPrivacy && <PrivacyModal handleSubmit={handleAcceptTermsOfUse}/> }
       {modalChoice && <ModalChoiceAccess onClose={() => setModalChoice(false)} />}

        <Header />
        {threadIsLoading && <Loader /> }
        {errorLimitAttemptThreadId &&
        <Flex width='100%' height={100} marginTop='100px' justifyContent='center'>
         <ToastCustom type='fail' fromToast={false} title='AI Indisponível no momento :(' description='Por favor tente novamente mais tarde ou entre em contato com o time de suporte.' />
        </Flex>
}

        {threadId && !threadIsLoading && <Chatbot showCompanyUseCase={showCompanyUseCase} start={start} setStart={setStart} handleDeleteFavorite={handleDeleteFavorite} messages={messages} isLoading={isLoading} handleSendMessage={handleSendMessage} chatLoading={chatLoading} handleRatingMessage={handleRatingMessage} suggestions={suggestions} favorites={favorites} handleCreateFavorite={handleCreateFavorite} handleClearChat={handleClearChat} handlePreFillMessage={handlePreFillMessage} preFillMessage={preFillMessage} /> }

    </Flex>
  )
}

export { ChatbotPage }
