/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable import/extensions */
import React, { FC, useEffect, useState } from 'react'

import './styles.css'
// import Header from './components/Header'
import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Text, Image, useTheme, Button } from '@chakra-ui/react'
import { HandleSendMessageProps, IMessagesChatProps } from '../../Hooks/useChat'
import { useScreenSizes } from '../../utils/screenSizes'
import { ChatbotInput } from '../ChatbotInput'
import { Logo } from '../Logo'
import { Messages } from './Messages'
import { TRatingMessageDTO } from '../../Services/protocols/service-chat'
import { Tabs } from './Tabs'
import { Suggestions } from './Suggestions'
import { Favorites } from './Favorites'
import { Chat } from './Chat'
import { TListSuggestionResponse } from '../../Services/protocols/service-suggestion'
import { IFavoriteEntity, TListFavoritesResponse } from '../../Services/protocols/service-favorites'
import { ToastCustom } from '../ToastCustom'
import { useSearchParams } from 'react-router-dom'
import Brand from '../../Assets/brand-image.png'
import { start } from 'repl'
import { ShowCompanyUseCase } from '../../Usecase/Companies/show-company-usecase'

interface Props {
  messages: IMessagesChatProps[]
  isLoading: boolean
  handleSendMessage: (data: HandleSendMessageProps) => Promise<void>
  chatLoading: string
  handleRatingMessage: (data: TRatingMessageDTO) => Promise<void>
  suggestions: TListSuggestionResponse[]
  favorites: IFavoriteEntity[]
  handleCreateFavorite: (name: string, messageId: string) => Promise<string | undefined>
  handleDeleteFavorite: (id: string) => Promise<void>
  handleClearChat: () => Promise<void>
  start: boolean
  setStart: (state: boolean) => void
  handlePreFillMessage: (data: HandleSendMessageProps) => void
  preFillMessage: string
  showCompanyUseCase: ShowCompanyUseCase

}
const Chatbot: FC<Props> = ({ messages, isLoading, handleSendMessage, chatLoading, handleRatingMessage, suggestions, favorites, handleCreateFavorite, handleDeleteFavorite, handleClearChat, setStart, start, handlePreFillMessage, preFillMessage, showCompanyUseCase }) => {
  const { isMobile } = useScreenSizes()
  const [searchParams] = useSearchParams()

  const fromFloatButton = searchParams.get('fromFloatButton')
  const theme = useTheme()
  useEffect(() => {
    if (fromFloatButton) {
      setStart(true)
    }
  }, [fromFloatButton])

  return (

      <Flex flex={1} direction="column" paddingTop={isMobile ? '0px' : '41px'} overflowY='hidden' >

        {!start && (
            <Modal isOpen onClose={() => {}} isCentered size='5xl'>
            <ModalOverlay />
            <ModalContent
              shadow="box-shadow: 0px 0px 12px 0px #00000026"
              bgColor={theme.colors.white.primary}
              border="none"
              borderRadius="16px"
              width={isMobile ? '90%' : '608px'}
              height="507px"
            >
              <ModalBody padding="46px 35px" >
                <Image src={Brand} alt="Brand" w="112px" h="30px" />
                <Box paddingX={ isMobile ? 0 : '90px'} marginTop='41px'>
                  <Text
                    fontWeight="900"
                    fontSize="32px"
                    color={theme.colors.gray.third}
                    fontFamily={theme.fonts.bodyBold}
                  >
                    Bem-vindo
                  </Text>
                  <Text
                    fontWeight="700"
                    fontSize="20px"
                    color={theme.colors.gray.third}
                    fontFamily={theme.fonts.body}
                    marginTop='-10px'
                  >
                    para respostas mais precisas
                  </Text>

                  <Text
                    fontWeight="400"
                    fontSize="16px"
                    color={theme.colors.gray.third}
                    marginTop="28px"
                    fontFamily={theme.fonts.body}
                  >
                    Compreenda a estrutura de tabelas
                  </Text>

                  <a href='https://app.nalk.com.br/browse/databases' target='_blank' rel="noreferrer">
                  <Text
                    fontWeight="800"
                    fontSize="16px"
                    textDecoration="underline"
                    color={theme.colors.gray.third}
                    fontFamily={theme.fonts.bodyBold}
                  >
                    Banco de dados
                  </Text>
                  </a>

                  <Text
                    fontWeight="400"
                    fontSize="16px"
                    color={theme.colors.gray.third}
                    marginTop="28px"
                  >
                    Especifique em suas perguntas variáveis como:
                  </Text>
                  <Text
                    fontWeight="800"
                    fontSize="16px"
                    textDecoration="underline"
                    color={theme.colors.gray.third}
                    fontFamily={theme.fonts.bodyBold}
                  >
                    Indicador, etapa, período e pipeline
                  </Text>
{/*
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    color={theme.colors.gray.third}
                    marginTop="51px"
                  >
                    Em conformidade com a LGPD, não exibimos dados sensíveis como nomes e
                    IDs.
                  </Text>
                  <Text
                    fontWeight="400"
                    fontSize="12px"
                    color={theme.colors.gray.third}
                    marginTop="15px"
                  >
                    Usamos como fonte de dados tabelas Cohort (vendas) e de mídia paga
                    (marketing)
                  </Text> */}

                  <Flex w="100%" justifyContent="center">
                    <Button
                      width="329px"
                      h="48px"
                      borderRadius="40px"
                      bgColor={theme.colors.blue.default}
                      marginTop="37px"
                      onClick={() => setStart(true)}
                      color="white"
                    >
                      Iniciar
                    </Button>
                  </Flex>
                </Box>
              </ModalBody>
            </ModalContent>
          </Modal>

        )}

        {!isMobile && <Logo />}

        <Chat showCompanyUseCase={showCompanyUseCase} handleDeleteFavorite={handleDeleteFavorite} handleCreateFavorite={handleCreateFavorite} mode='fullscreen' messages={messages} isLoading={isLoading} handleSendMessage={handleSendMessage} chatLoading={chatLoading} handleRatingMessage={handleRatingMessage} suggestions={suggestions} favorites={favorites} handleClearChat={handleClearChat} start={start} handlePreFillMessage={handlePreFillMessage} preFillMessage={preFillMessage} />
      </Flex>

  )
}

export { Chatbot }
