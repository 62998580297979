/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Flex, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Stack, Text, useTheme, useToast } from '@chakra-ui/react'
import React, { FC, useState } from 'react'
import { MdLogin } from 'react-icons/md'
import { StorageLocalstorage } from '../../../Shared/storage/storage-localstorage'
import { useNavigate } from 'react-router-dom'
import { clearCookie } from '../../../utils/cookies'
import { ICollaboratorProps } from '../../../Pages/Backoffice/PanelCustomer'
import { AiOutlineDelete } from 'react-icons/ai'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ToastCustom } from '../../ToastCustom'
import { Input } from '../../Form/Input'
import { OptionsProps, Select } from '../../Form/Select'
import { Checkbox } from '../../Form/Checkbox'
import { CreateUserUseCase } from '../../../Usecase/User/create-user-usecase'
import { IUserEntity, TCreateUserPayload, TUpdateUserPayload } from '../../../Services/protocols/service-user'
import { UpdateUserUseCase } from '../../../Usecase/User/update-user-usecase'

interface IModalNewCollaboratorProps {
  onClose: () => void
  onSubmit: () => void
  user?: IUserEntity
  roles: OptionsProps[]
  areas: OptionsProps[]
  createUserUseCase: CreateUserUseCase
  updateUserUseCase: UpdateUserUseCase
  companyId: string
  cbFunction: () => void
}

export enum MetabaseGroupsTypesEnum {
  SB_GERAL = 'SB_GERAL',
  SB_MARKETING = 'SB_MARKETING',
  SB_SALES = 'SB_VENDAS',
  V_GERAL = 'V_GERAL',
  V_MARKETING = 'V_MARKETING',
  V_SALES = 'V_VENDAS',
}

const ModalNewCollaborator: FC<IModalNewCollaboratorProps> = ({ onClose, roles, areas, createUserUseCase, companyId, cbFunction, user, updateUserUseCase }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  const { handleSubmit, register, formState } = useForm({
    // resolver: yupResolver(SignInSchemaValidation)
  })

  const getPermissions = (values: any): string[] => {
    const { sb_geral, sb_mkt, sb_sale, v_geral, v_mkt, v_sale } = values
    const permissions = []

    if (sb_geral) {
      permissions.push(MetabaseGroupsTypesEnum.SB_GERAL)
    }

    if (sb_mkt) {
      permissions.push(MetabaseGroupsTypesEnum.SB_MARKETING)
    }

    if (sb_sale) {
      permissions.push(MetabaseGroupsTypesEnum.SB_SALES)
    }

    if (v_geral) {
      permissions.push(MetabaseGroupsTypesEnum.V_GERAL)
    }

    if (v_mkt) {
      permissions.push(MetabaseGroupsTypesEnum.V_MARKETING)
    }

    if (v_sale) {
      permissions.push(MetabaseGroupsTypesEnum.V_SALES)
    }

    return permissions
  }

  const handleCreateNewCustomer: SubmitHandler<any> = async (values) => {
    try {
      setLoading(true)

      if (user?.id) {
        // update
        const payload: TUpdateUserPayload = {
          companyId,
          collaborators: [
            {
              userId: user.id,
              firstName: values?.name,
              lastName: values?.surname,
              email: values?.email,
              groups: getPermissions(values),
              companyRoleId: values?.position,
              companyAreaId: values?.area
            }
          ]

        }

        const updateResult = await updateUserUseCase.handle(payload)

        if (updateResult.isFailure) {
          toast({
            position: 'top-right',
            render: () => <ToastCustom type='fail' title='Erro' description={updateResult.error?.error ?? 'Erro ao editar usuário. Por favor, tente novamente'} />
          })

          return
        }

        toast({
          position: 'top-right',
          render: () => <ToastCustom type='success' title='Sucesso' description='Usuário Atualizado' />
        })

        cbFunction()
      } else {
        const payload: TCreateUserPayload = {
          companyId,
          collaborator: {
            firstName: values?.name,
            lastName: values?.surname,
            email: values?.email,
            groups: getPermissions(values),
            userRoleId: values?.position,
            userAreaId: values?.area
          }

        }

        const createResult = await createUserUseCase.handle(payload)

        if (createResult.isFailure) {
          toast({
            position: 'top-right',
            render: () => <ToastCustom type='fail' title='Erro' description={createResult.error?.error ?? 'Erro ao criar usuário. Por favor, tente novamente'} />
          })

          return
        }

        toast({
          position: 'top-right',
          render: () => <ToastCustom type='success' title='Sucesso' description='Usuário Cadastrado' />
        })

        cbFunction()
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoading(false)
    }
  }

  return (
        <Modal isOpen onClose={onClose} isCentered size='6xl'>
          <ModalOverlay />
          <ModalContent shadow='box-shadow: 0px 0px 12px 0px #00000026' bgColor={theme.colors.white.primary} border='none' borderRadius='16px'>

          <ModalBody padding="48px">
          <ModalCloseButton w='15px' h='15px' marginTop='5px' marginRight='3px' />
          <Flex
            as="form"

            flexDir="column"
            onSubmit={handleSubmit(handleCreateNewCustomer)}
            bg="white"

        >

                <Stack gap='12px' padding='0px' borderRadius='10px'>
                    <Text>Dados cadastrais:</Text>
                        <Flex gap='12px'>
                            <Input
                            type="text"
                            label="Nome"
                            placeholder='Preencher nome'
                            labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                            style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                            _placeholder={{ color: 'gray.third' }}
                            defaultValue={user?.metabaseUser?.firstName}
                            {...register('name')}
                            />

                            <Input
                            type="text"
                            label="Sobrenome"
                            placeholder='Preencher sobrenome'
                            labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                            style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                            _placeholder={{ color: 'gray.third' }}
                            defaultValue={user?.metabaseUser?.lastName}
                            {...register('surname')}
                            />

                            <Input
                            type="text"
                            label="E-mail"
                            placeholder='Preencher e-mail'
                            labelStyle={{ fontWeight: '700', color: theme.colors.gray[900], marginBottom: 5 }}
                            style={{ borderRadius: 12, borderColor: '#B1C0E0', marginBottom: 15 }}
                            _placeholder={{ color: 'gray.third' }}
                            defaultValue={user?.metabaseUser?.email}
                            {...register('email')}
                            />

                    </Flex>

                    <Flex gap='12px'>
                    <Select
                            label='Cargo'
                            placeholder='Selecionar Cargo'
                            options={roles}
                            defaultValue={user?.companyRole?.id}
                            {...register('position')}
                            />

                            <Select
                            label='Área'
                            placeholder='Selecionar Área'
                            options={areas}
                            defaultValue={user?.companyArea?.id}
                            {...register('area')}
                            />

                    </Flex>

                    <Text>Grupos de acesso:</Text>

                    <Flex gap='12px'>

                            <Checkbox
                            label='SB Geral'
                            defaultChecked={!!user?.metabaseUser?.sbGeralGroupId}
                            {...register('sb_geral')}
                            />

                            <Checkbox
                            label='SB MKT'
                            defaultChecked={!!user?.metabaseUser?.sbMarketingGroupId}
                            {...register('sb_mkt')}
                            />

                            <Checkbox
                            label='SB Venda'
                            defaultChecked={!!user?.metabaseUser?.sbSalesGroupId}
                            {...register('sb_sale')}
                            />

                            <Checkbox
                            label='V Geral'
                            defaultChecked={!!user?.metabaseUser?.vGeralGroupId}
                            {...register('v_geral')}
                            />

                            <Checkbox
                            label='V MKT'
                            defaultChecked={!!user?.metabaseUser?.vMarketingGroupId}
                            {...register('v_mkt')}
                            />

                            <Checkbox
                            label='V Venda'
                            defaultChecked={!!user?.metabaseUser?.vSalesGroupId}
                            {...register('v_sale')}
                            />

                            <Box w='150px'>
                            {/* <Button
                                as="button"
                                onClick={() => setShowModalConfirmation({ show: true, collaboradorId: i.id })}
                                size="sm"
                                fontSize="sm"
                                colorScheme="red"

                                width={150}
                                leftIcon={<Icon as={AiOutlineDelete} fontSize="16" />}
                                mr={2}
                            >
                                Excluir
                            </Button> */}

                            </Box>

                    </Flex>

                </Stack>

                   <Flex justifyContent='flex-end' w='100%' padding='0px' marginTop='auto'>
                            <Button
                                type="submit"
                                w='278px'
                                height='49px'
                                borderRadius='40px'
                                mt="50px"
                                bg="gray.900"
                                color="gray.800"
                                fontWeight='400'
                                isLoading={formState.isSubmitting}
                            >
                            Salvar
                            </Button>
                          </Flex>
          </Flex>

          </ModalBody>

          </ModalContent>
      </Modal>
  )
}

export { ModalNewCollaborator }
