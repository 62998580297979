/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Stack, theme, useTheme, useToast } from '@chakra-ui/react'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { IUserEntity } from '../../../Services/protocols/service-user'
import { CreateUserUseCase } from '../../../Usecase/User/create-user-usecase'
import { UpdateUserUseCase } from '../../../Usecase/User/update-user-usecase'
import { Input } from '../../Form/Input'
import { OptionsProps, Select } from '../../Form/Select'
import { ToastCustom } from '../../ToastCustom'
import { CreateIntegrationUseCase } from '../../../Usecase/Integrations/create-integration-usecase'

import { ListIntegrationSourcesUseCase } from '../../../Usecase/Integrations/list-integration-sources-usecase'
import { IAirbyteIntegrationSource, IIntegrationEntity, TCreateIntegrationsPayload, TUpdateIntegrationsPayload } from '../../../Services/interfaces/integration.interface'
import { ShowIntegrationUseCase } from '../../../Usecase/Integrations/show-integration-usecase'
import { Loader } from '../../Loader'
import { UpdateIntegrationUseCase } from '../../../Usecase/Integrations/update-integration-usecase'

interface IModalNewIntegrationProps {
  onClose: () => void
  onSubmit: () => void
  user?: IUserEntity
  roles: OptionsProps[]
  areas: OptionsProps[]
  createIntegrationUseCase: CreateIntegrationUseCase
  listIntegrationSourcesUseCase: ListIntegrationSourcesUseCase
  showIntegrationUseCase: ShowIntegrationUseCase
  updateUserUseCase: UpdateUserUseCase
  companyId: string
  cbFunction: () => void
  integration?: IIntegrationEntity
  updateIntegrationUseCase: UpdateIntegrationUseCase
}

const ModalNewIntegration: FC<IModalNewIntegrationProps> = ({ onClose, roles, areas, createIntegrationUseCase, companyId, cbFunction, user, listIntegrationSourcesUseCase, integration, updateIntegrationUseCase }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const toast = useToast()
  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(
    null
  )
  const [integrationsSchema, setIntegratinsSchema] = useState<IAirbyteIntegrationSource[]>([])
  const [integrationsOptions, setIntegrationsOptions] = useState<OptionsProps[]>([])
  const [isLoading, setIsLoading] = useState(true)
  console.log('integration:', integration)
  const { handleSubmit, register, formState, watch, unregister, resetField, setValue } = useForm({
  // resolver: yupResolver(SignInSchemaValidation)
  })

  const handleCreateIntegration = async (values: any) => {
    try {
      setIsLoading(true)

      const payload: TCreateIntegrationsPayload = Object.entries({
        companyId,
        extra: {
          ...values
        },
        type: String(values?.type)?.toUpperCase()
      }).reduce<TCreateIntegrationsPayload>((acc, [key, value]) => {
        if (value !== '' && value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0) && !(typeof value === 'object' && Object.keys(value).length === 0)) {
          acc[key as keyof TCreateIntegrationsPayload] = value
        }
        return acc
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      }, {} as TCreateIntegrationsPayload)

      delete payload?.extra?.type

      const createResult = await createIntegrationUseCase.handle(payload)

      if (createResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={createResult.error?.error ?? 'Erro ao criar integração. Por favor, tente novamente'} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description='Integração Cadastrada' />
      })

      cbFunction()
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateIntegrations = async (values: any) => {
    try {
      setIsLoading(true)

      const payload: TUpdateIntegrationsPayload = Object.entries({
        integrationId: integration?.id,
        extra: {
          ...values
        }
      }).reduce<TUpdateIntegrationsPayload>((acc, [key, value]) => {
        if (value !== '' && value !== null && value !== undefined && !(Array.isArray(value) && value.length === 0) && !(typeof value === 'object' && Object.keys(value).length === 0)) {
          acc[key as keyof TUpdateIntegrationsPayload] = value
        }
        return acc
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      }, {} as TUpdateIntegrationsPayload)

      delete payload?.extra?.type

      const updateResult = await updateIntegrationUseCase.handle(payload)

      if (updateResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={updateResult.error?.error ?? 'Erro ao editar integração. Por favor, tente novamente'} />
        })

        return
      }

      toast({
        position: 'top-right',
        render: () => <ToastCustom type='success' title='Sucesso' description='Integração Editada' />
      })

      cbFunction()
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro na operação. Por favor, tente novamente'} />
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleIntegration: SubmitHandler<any> = async (values) => {
    try {
      integration?.id ? await handleUpdateIntegrations(values) : await handleCreateIntegration(values)
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    }
  }

  const handleLoadIntegrationSources = async () => {
    try {
      setIsLoading(true)

      const listResult = await listIntegrationSourcesUseCase.handle()

      if (listResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={listResult.error?.error ?? 'Erro ao buscar as integrações. Por favor, tente novamente'} />
        })

        return
      }

      const response = listResult.getValue()

      if (response?.length) {
        setIntegratinsSchema(response)
        const normalize = response.map(i => {
          return {
            value: i.sourceType,
            label: i.sourceName
          }
        })

        setIntegrationsOptions(normalize)
      }
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setIsLoading(false)
    }
  }

  // const handleIntegrationsFields = (integrationValue: any): void => {
  //   if (!integrationValue) return

  //   unregister('urlBase')

  //   resetField('token')

  //   unregister('clientId')
  //   unregister('clientSecret')
  //   unregister('refreshToken')
  //   unregister('token')

  //   if (tokenTypes.includes(integrationValue.toUpperCase())) {
  //     setIntegrationType('token')
  //     // unregister('clientId')
  //     // unregister('clientSecret')
  //     // unregister('refreshToken')
  //   }

  //   if (secretTypes.includes(integrationValue.toUpperCase())) {
  //     setIntegrationType('client_secret')
  //     // unregister('token')
  //   }

  //   if (baseUrlTypes?.includes(integrationValue.toUpperCase())) {
  //     setHasBaseUrl(true)
  //   } else {
  //     setHasBaseUrl(false)
  //   }

  //   if (refreshTokenTypes?.includes(integrationValue.toUpperCase())) {
  //     setHasRefreshToken(true)
  //   } else {
  //     setHasRefreshToken(false)
  //   }

  //   setCRMSelected(integrationValue.toUpperCase())
  // }

  // useEffect(() => {
  //   handleIntegrationsFields(integrationValue)
  // }, [integrationValue])

  /// /

  const integrationType = watch('type')

  useEffect(() => {
  // Quando o usuário troca de CRM, removemos os campos antigos
    const selectedSchema = integrationsSchema?.find(
      (schema: IAirbyteIntegrationSource) => schema.sourceType === integrationType
    )

    if (selectedSchema) {
      selectedSchema.credentials.forEach((field) => {
        unregister(field.inputKeyName)
        // setValue(field.inputKeyName, '') // Limpa o valor anterior
      })
    }

    return () => {
      if (selectedSchema) {
        selectedSchema.credentials.forEach((field) => {
          unregister(field.inputKeyName)
        })
      }
    }
  }, [integrationType])

  useEffect(() => {
    void handleLoadIntegrationSources()
  }, [])

  useEffect(() => {
    if (integration?.type) {
      setSelectedIntegration(integration?.type)
      setValue('type', integration?.type)

      Object.entries(integration?.extra).forEach(([key, value]) => {
        setValue(key, value)
      })
    }
  }, [integration, integrationsOptions])

  return (
        <Modal isOpen onClose={onClose} isCentered size='6xl'>
          {isLoading && <Loader /> }
          <ModalOverlay />
          <ModalContent shadow='box-shadow: 0px 0px 12px 0px #00000026' bgColor={theme.colors.white.primary} border='none' borderRadius='16px'>

          <ModalBody padding="48px">
          <ModalCloseButton w='15px' h='15px' marginTop='5px' marginRight='3px' />
          <Flex
            as="form"

            flexDir="column"
            onSubmit={handleSubmit(handleIntegration)}
            bg="white"

        >
          <Stack gap="12px" padding="0px" borderRadius="10px">
          {/* Select para escolher a integração */}
          <Flex gap="12px">
            <Select
              labelStyle={{ fontWeight: '700', color: theme.colors.gray[900] }}
              label='Integração'
              placeholder="Selecione uma opção"
              options={integrationsOptions}
              {...register('type')}
              onChange={(e) => setSelectedIntegration(e.target.value)}
              defaultValue={integration?.type}
              isDisabled={!!integration?.type}
            >
              {integrationsSchema.map((integration) => (
                <option key={integration.sourceType} value={integration.sourceType}>
                  {integration.sourceName}
                </option>
              ))}
            </Select>
          </Flex>

            {/* Campos dinâmicos */}
            {selectedIntegration &&
                integrationsSchema
                  .find((schema) => schema.sourceType === selectedIntegration)
                  ?.credentials.map((field) => (
                    <Flex gap="12px" key={field.inputKeyName}>
                      <Input
                      labelStyle={{ fontWeight: '700', color: theme.colors.gray[900] }}
                        type="text"
                        label={field.inputName}
                        placeholder={`Preencher ${field.inputName}`}
                        {...register(field.inputKeyName)}
                        helperText={field?.hint ?? ''}
                      />
                    </Flex>
                  ))}
            </Stack>

            <Flex justifyContent='flex-end' w='100%' padding='0px' marginTop='auto'>
              <Button
                  type="submit"
                  w='278px'
                  height='49px'
                  borderRadius='40px'
                  mt="50px"
                  bg="gray.900"
                  color="gray.800"
                  fontWeight='400'
                  isLoading={formState.isSubmitting}
              >
              Salvar
              </Button>
            </Flex>
          </Flex>

          </ModalBody>

          </ModalContent>
      </Modal>
  )
}

export { ModalNewIntegration }
