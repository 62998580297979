import { IUserEntity, IUserProfile, UserApiRemoteService } from '../../Services/protocols/service-user'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IUsecase } from '../../Shared/Protocol/protocol-usecase'

export class ProfileUserUseCase implements IUsecase<void, IUserProfile | undefined> {
  constructor (private readonly repository: UserApiRemoteService) {}

  public async handle (): Promise<Result<IUserProfile>> {
    try {
      const result = await this.repository.getProfile()

      if (result.isFailure) {
        return Result.fail(result.error ?? new Error('Unknown error'))
      }

      const value = result.getValue()

      if (value === null) {
        return Result.fail(new Error('Profile not found'))
      }
      return Result.ok(value)
    } catch (error: any) {
      console.log('respons error: ', error)
      return Result.fail(error)
    }
  }
}
