import React, { FC, useState } from 'react'
import {
  Popover, PopoverTrigger, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, PopoverContent,
  Box, Text, Spinner, PlacementWithLogical, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Button
} from '@chakra-ui/react'
import { MdOutlineInfo } from 'react-icons/md'
import { ICompanyFiles } from '../../../Services/interfaces/companies.interface'
import { useScreenSizes } from '../../../utils/screenSizes'
import { load } from '@fingerprintjs/fingerprintjs'

interface InfoFilesPopoverProps {
  data?: ICompanyFiles
  loading: boolean
  position?: PlacementWithLogical
}

const InfoFilesPopover: FC<InfoFilesPopoverProps> = ({ data, loading, position }) => {
  const { isMobile } = useScreenSizes()
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const modalTypeInfo = true // testando visualizacao como modal ao invés de popover

  return (
    <Box zIndex={999999999}>
      {loading && <Spinner />}

      {!data?.cohort?.count_rows && !data?.midias?.count_rows && !loading
        ? <MdOutlineInfo fontSize={24} cursor="pointer" />
        : (

            modalTypeInfo
              ? (
        <>
        {!loading && (<span onClick={openModal}>
              <MdOutlineInfo fontSize={24} cursor="pointer" />
          </span>)}

          <Modal isOpen={isOpen} onClose={closeModal} isCentered>
            <ModalOverlay />
            <ModalContent margin='20px'>
              <ModalHeader>Informações</ModalHeader>
              <ModalCloseButton />
              <ModalBody padding="20px">
                {data?.cohort?.count_rows && (
                  <Text fontSize="14px" color="gray.500" mb="10px">
                    <b>Cohort</b>: Pergunte sobre dados entre
                    <b> {data?.cohort?.dates?.min} </b> e
                    <b> {data?.cohort?.dates?.max}</b>
                  </Text>
                )}
                {data?.midias?.count_rows && (
                  <Text fontSize="14px" color="gray.500">
                    <b>Mídias</b>: Pergunte sobre dados entre
                    <b> {data?.midias?.dates?.min} </b> e
                    <b> {data?.midias?.dates?.max}</b>
                  </Text>
                )}
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="blue" onClick={closeModal}>Fechar</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
                )
              : (

        <Popover placement={position ?? 'right'}>
          <PopoverTrigger>
            {loading
              ? (
              <Spinner />
                )
              : (
              <span>
                <MdOutlineInfo fontSize={24} cursor="pointer" />
              </span>
                )}
          </PopoverTrigger>
          <PopoverContent bg="white" zIndex={999999999}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Informações</PopoverHeader>
            <PopoverBody padding="20px">
              {data?.cohort?.count_rows && (
                <Text fontSize="14px" color="gray.500" mb="10px">
                  <b>Cohort</b>: Pergunte sobre dados entre
                  <b> {data?.cohort?.dates?.min} </b> e
                  <b> {data?.cohort?.dates?.max}</b>
                </Text>
              )}
              {data?.midias?.count_rows && (
                <Text fontSize="14px" color="gray.500">
                  <b>Mídias</b>: Pergunte sobre dados entre
                  <b> {data?.midias?.dates?.min} </b> e
                  <b> {data?.midias?.dates?.max}</b>
                </Text>
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
                )

          )}

    </Box>
  )
}

export default InfoFilesPopover
