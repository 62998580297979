import React, { FC } from 'react'
import { HStack, Box, Text, Stack } from '@chakra-ui/react'
import { PaginationItem } from './PaginationItem'

interface PaginationProps {
  totalCountOfRegisters: number
  registersPerPage?: number
  currentPage?: number
  onPageChange: (page: number) => void
}

const siblingsCount = 1

function generatePagesArray (from: number, to: number): number[] {
  return Array.from({ length: Math.max(to - from + 1, 0) }, (_, index) => from + index).filter(page => page > 0)
}

const Pagination: FC<PaginationProps> = ({
  totalCountOfRegisters,
  registersPerPage = 20,
  currentPage = 1,
  onPageChange
}) => {
  const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage)
  const previousPages = generatePagesArray(Math.max(1, currentPage - siblingsCount), currentPage - 1)
  const nextPages = generatePagesArray(currentPage + 1, Math.min(currentPage + siblingsCount, lastPage))

  return (
    <Stack direction={['column', 'row']} mt="8" justify="space-between" align="center" spacing="6">
      <Box>
        Total de Registros: <strong>{totalCountOfRegisters || 0}</strong>
      </Box>
      <HStack spacing="2" color="white">
        {currentPage > 1 + siblingsCount && (
          <>
            <PaginationItem onPageChange={onPageChange} number={1} />
            {currentPage > 2 + siblingsCount && <Text color="black" w="8" textAlign="center" fontSize='24px'>...</Text>}
          </>
        )}

        {previousPages.map(page => (
          <PaginationItem key={page} onPageChange={onPageChange} number={page} />
        ))}

        <PaginationItem onPageChange={onPageChange} number={currentPage} isCurrent />

        {nextPages.map(page => (
          <PaginationItem key={page} onPageChange={onPageChange} number={page} />
        ))}

        {currentPage + siblingsCount < lastPage && (
          <>
            {currentPage + 1 + siblingsCount < lastPage && <Text color="black" w="8" textAlign="center" fontSize='24px'>...</Text>}
            <PaginationItem onPageChange={onPageChange} number={lastPage} />
          </>
        )}
      </HStack>
    </Stack>
  )
}

export { Pagination }
