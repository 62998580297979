import React, { FC, useCallback, useEffect, useState } from 'react'
import { Tabs } from '../Tabs'
import { Messages } from '../Messages'
import { Favorites } from '../Favorites'
import { Suggestions } from '../Suggestions'
import { ChatbotInput } from '../../ChatbotInput'
import { HandleSendMessageProps, IMessagesChatProps, useChat } from '../../../Hooks/useChat'
import { TRatingMessageDTO } from '../../../Services/protocols/service-chat'
import { Box, Flex, useTheme, useToast } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { IModeChatType } from '../Interface/IModeChat'
import { TListSuggestionResponse } from '../../../Services/protocols/service-suggestion'
import { IFavoriteEntity, TListFavoritesResponse } from '../../../Services/protocols/service-favorites'
import { text } from 'stream/consumers'
import { ShowCompanyUseCase } from '../../../Usecase/Companies/show-company-usecase'
import InfoFilesPopover from '../../Popover/InfoFilesPopover/info-files-popover.component'
import { ToastCustom } from '../../ToastCustom'
import { ICompanyFiles } from '../../../Services/interfaces/companies.interface'
import { format, parseISO } from 'date-fns'
import { useScreenSizes } from '../../../utils/screenSizes'

interface Props {
  messages: IMessagesChatProps[]
  isLoading: boolean
  handleSendMessage: (data: HandleSendMessageProps) => Promise<void>
  chatLoading: string
  handleRatingMessage: (data: TRatingMessageDTO) => Promise<void>
  mode: IModeChatType
  closeFunction?: () => void
  suggestions: TListSuggestionResponse[]
  favorites: IFavoriteEntity[]
  handleCreateFavorite: (name: string, messageId: string) => Promise<string | undefined>
  handleDeleteFavorite: (id: string) => Promise<void>
  handleClearChat: () => Promise<void>
  start?: boolean
  handlePreFillMessage: (data: HandleSendMessageProps) => void
  preFillMessage: string
  showCompanyUseCase: ShowCompanyUseCase
}

const Chat: FC<Props> = ({ chatLoading, handleClearChat, handleRatingMessage, handleSendMessage, isLoading, messages, start, mode, closeFunction, suggestions, favorites, handleCreateFavorite, handleDeleteFavorite, handlePreFillMessage, preFillMessage, showCompanyUseCase }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [forceScroll, setForceScroll] = useState(false)
  const [filesData, setFilesData] = useState<ICompanyFiles>()
  const [loadingFiles, setLoadingFiles] = useState(true)
  const { companyId } = useChat()
  const theme = useTheme()
  const toast = useToast()
  const { isMobile } = useScreenSizes()

  const handleNewMessage = useCallback(async ({ text, favoriteId }: HandleSendMessageProps) => {
    setActiveTab(0)
    await handleSendMessage({ text, favoriteId })
  }, [handleSendMessage])

  const cbForceScroll = () => {
    setForceScroll(true)

    setTimeout(() => {
      setForceScroll(false)
    }, 500)
  }

  const loadCompany = async (companyId: string) => {
    try {
      const getResult = await showCompanyUseCase.handle(companyId)

      if (getResult.isFailure) {
        toast({
          position: 'top-right',
          render: () => <ToastCustom type='fail' title='Erro' description={getResult.error?.error ?? 'Erro ao criar integração. Por favor, tente novamente'} />
        })

        return
      }

      const result = getResult.getValue()

      if (result?.files) {
        const files: ICompanyFiles = {
          cohort: {
            count_rows: result?.files?.cohort?.count_rows,
            dates: {
              min: format(parseISO(result?.files?.cohort?.dates?.min), 'dd/MM/yyyy'),
              max: format(parseISO(result?.files?.cohort?.dates?.max), 'dd/MM/yyyy')
            },
            has_file: result?.files?.cohort?.has_file

          },
          midias: {
            count_rows: result?.files?.midias?.count_rows,
            dates: {
              min: format(parseISO(result?.files?.midias?.dates?.min), 'dd/MM/yyyy'),
              max: format(parseISO(result?.files?.midias?.dates?.max), 'dd/MM/yyyy')
            },
            has_file: result?.files?.midias?.has_file

          }
        }

        setFilesData(files)
      }

      console.log('getResult: ', getResult.getValue())
    } catch (error) {
      toast({
        position: 'top-right',
        render: () => <ToastCustom type='fail' title='Erro' description={error.response.data.error ?? 'Erro ao logar. Por favor, tente novamente'} />
      })
    } finally {
      setLoadingFiles(false)
    }
  }

  useEffect(() => {
    loadCompany(companyId)
  }, [])

  return (
        <Flex direction="column" alignItems={mode === 'popup' ? 'flex-start' : 'center' } padding={0} height='100%' >
          {mode === 'popup' && (
            <Flex justifyContent='space-between' padding="24px" borderBottom='solid 1px' borderBottomColor={theme.colors.gray.third} w='100%' marginBottom={activeTab === 0 && mode === 'popup' ? '13px' : '36px'} >
            <Tabs activeTab={activeTab} setActiveTab={setActiveTab} mode={mode} />
            <Flex alignItems='center'>
              <InfoFilesPopover data={filesData} loading={loadingFiles} />

              <MdClose style={{ marginLeft: '12px' }} fontSize={24} onClick={closeFunction} cursor='pointer' />
            </Flex>

          </Flex>
          )}

          {mode === 'fullscreen' && !isMobile && <Flex justifyContent='space-between' alignItems='center' w='100%' marginTop='3%' marginBottom='2%'> <Box /> <Flex justifyContent='space-between' alignItems='center'> <Tabs activeTab={activeTab} setActiveTab={setActiveTab} mode={mode} /> <InfoFilesPopover data={filesData} loading={loadingFiles} /></Flex> <Box/></Flex> }

          {mode === 'fullscreen' && isMobile && <Tabs activeTab={activeTab} setActiveTab={setActiveTab} mode={mode} />}

          {activeTab === 0 && start && <Messages handleDeleteFavorite={handleDeleteFavorite} handleCreateFavorite={handleCreateFavorite} messages={messages} chatLoading={chatLoading} handleRatingMessage={handleRatingMessage} mode={mode} handleSendMessage={handleSendMessage} handleClearChat={handleClearChat} forceScroll={forceScroll} />}

          {activeTab === 1 && <Suggestions mode={mode} data={suggestions} handleSendMessage={handleNewMessage} handlePreFillMessage={handlePreFillMessage} preFillMessage={preFillMessage} />}

          {activeTab === 2 && <Favorites mode={mode} data={favorites} handleSendMessage={handleNewMessage} handleDeleteFavorite={handleDeleteFavorite} /> }

          {!isLoading && <ChatbotInput showCompanyUseCase={showCompanyUseCase} handleSendMessage={handleSendMessage} mode={mode} handlePreFillMessage={handlePreFillMessage} preFillMessage={preFillMessage} cleanFunction={() => setActiveTab(0)} cbForceScroll={cbForceScroll} />}
        </Flex>
  )
}

export { Chat }
